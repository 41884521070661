import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'yohaku-bi',
  id: 3,
  total_amount: 3000000,
  title: 'メンタルヘルスを支える湯治ケーション',
  issues: ['空き家', '集落の小規模化', '地域産業の衰退'],
  fund_uses: [
    {
      id: 1,
      title: '床の張替え　600,000円',
      description: `北側の部屋の床が腐ちてしまい使用できない状況になっているため、張替えと間取りを変更し、滞在しやすくする。`,
      image: '/yohaku-bi/use1.jpg',
    },
    {
      id: 1,
      title: 'キッチン改修　1,000,000円',
      description: `キッチンが腐ち、シンクなども錆びて使用できない状況になっているため、キッチンを改修し、長期滞在しやすくかつコミュニケーションをとりやすい形状に変更する。`,
      image: '/yohaku-bi/use2.jpg',
    },
    {
      id: 1,
      title: '温泉の給湯管の引き直し　300,000円',
      description: `温泉の権利はあるものの、源泉からの給湯管が詰まってしまっているため、給湯管を引き直し、家で温泉に入れるようにする。`,
      image: '/yohaku-bi/use3.jpg',
    },
    {
      id: 1,
      title: '風呂場改修　1,100,000円',
      description: `タイルが剥がれ、浴槽も小さく錆びていて使用できないため、風呂場を改修し、温泉でリラックスできるようにする。`,
      image: '/yohaku-bi/use4.jpg',
    },
  ],
  purpose_title: '企業が湯治に来る、メンタルヘルスの町・湯来（ゆき）町へ',
  purpose_text: `湯来町は広島市内から車で50分、広島藩主の湯治場という歴史を持つ地で、美しい自然にも囲まれ、湯治ケーションに最適の地である。また現在、県立広島大学と共同で、川でのゆるやかな運動がストレス数値を下げられないか（清流セラピー）実証実験中で、年度内に結果が出る予定であり、これらの取組を通じ、湯来町＝メンタルヘルスの町、というブランディングをし、地域と企業両方の課題解決に取り組んでいくため、本プロジェクトを実行する。

企業に求められるメンタルヘルスにおいて、ストレスが高いと判定された社員への対策は限られる中、ストレスを強く感じてはいるが、病気と言えるほどでない従業員への早期メンタルケアの手段として、温泉地で無理なくリモートワークを行いつつ療養してもらう、という湯治ケーションが注目されている。企業に早期メンタルヘルス課題の解決手段を提供しつつ、湯来の地域課題でもある地域産業の衰退、集落の小規模化や空き家の問題を解決していく。`,
  purpose_image: '/yohaku-bi/purpose.jpg',
  project_title: '湯治ケーション施設の整備',
  project_text: `温泉が出る空き家を改修し、長期滞在可能な湯治ケーション施設として整備し、清流セラピーも活用しながら、企業の福利厚生の一環としてプログラムを提供できるよう準備を行う。
  またこうした取組を通じて、空き家を活用する利点を地域住民にも理解してもらい、空き家の活用が進まないという地域課題の解決にもつなげる。空き家の活用が進めば、湯治ケーション経由のサテライトオフィス造成や移住等も見込め、地域の持続可能性にもつながっていく。さらには、湯来町の温泉需要が増え、寂れてしまった温泉街の再生にもつなげ、地域住民の心の火を灯したい。`,
  project_image: '/yohaku-bi/project.jpg',
  about: `湯来町の地域課題としては、柱の産業であった①林業②酪農業③観光業が、「収益性の低さ」と、厳しい状況を巻き返していくような「人材不足」、そして「アクセスの悪化」も要因となり、産業として成立しなくなった。仕事がないことで人口流出は止まらず、少子高齢化が加速している。また、空き家の掘り起こし・活用が進まず、一定数いる移住希望者に情報を提供できず、定住者を受け入れる機会を逃している。
　当社は、これらの課題を解決するため、下記のような事業を行い、産業を復興させ、子育て世代が暮らせ、かつ地域住民が幸せに暮らせる、持続可能な地域となることを目指し設立され、活動している。`,
  video: 'https://www.youtube.com/embed/TwrPZQuaW9M?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
