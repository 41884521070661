import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'ogawamotors',
  id: 4,
  total_amount: 3000000,
  title: 'あなたのふるさと“甲奴”',
  issues: ['集落の小規模化・高齢化', '耕作放棄地', '地域資源の活用', '雇用の創出'],
  fund_uses: [
    {
      id: 1,
      title: '現地研修、スマイル10アール事業に係る運営費、広報費、謝金　 1,300,000円',
      description:
        '薪割りや農作業等、甲奴の暮らし体験プログラムである現地研修の運営費・交通費の他、スマイル10アール等の事業PRに充てます。豊かで緩やかなつながり創出の原点となります。',
      image: '/ogawamotors/use1.jpg',
    },
    {
      id: 1,
      title: `地域貢献事業に係る外部委託費　800,000円`,
      description: `広島県の新鮮な野菜・乳製品・焼き菓子等を販売する「Farmer's Market」を県外企業オフィスで出張開催するため、商品仕入や交通費等に充てます。モノの良さと作り手の思いを肌で感じて頂く第一歩になります。`,
      image: '/ogawamotors/use2.jpg',
    },
    {
      id: 1,
      title: `農地遠隔監視カメラ　900,000円`,
      description: `農地遠隔監視カメラを購入・設置し、いつでもどこでも農園の状況を確認出来る環境を構築します。あなたにとって甲奴がもっと身近な存在になります。`,
      image: '/ogawamotors/use3.jpg',
    },
  ],
  purpose_title: '地域のお困りごとを解決が、地域の担い手づくりにつながる',
  purpose_text: `人口減と高齢化による担い手不足により、地域資源である食味に優れた米の栽培が衰退。休耕田の拡大が地域の魅力を低下させ、若年層が進学や就職を機に甲奴を離れる社会減が継続しており、高齢化による自然減と相まって人口減を加速させ、それが新たな空き家の発生や地区の産業衰退につながる、という悪循環に陥っている。

このことから、大正時代から存在する小川モータースは自動車販売・修理・整備・板金、ガソリンスタンドなどの事業を行うことで、地域内のお困りごとを拾い上げ、地域の資源を活かした、多様な事業を生み出す活動を行なっている。これにより、地域の担い手となる雇用を生み出せることを期待している。`,
  purpose_image: '/ogawamotors/purpose.JPG',
  project_title: 'ふるさとを感じてもらう” 持続可能な仕事 ”をみんなでうみだす',
  project_text: `① 持続可能な農業をつくる「スマイル10アール事業」
農家からお米を当社が買い取り、都市部の企業と契約をして、お米を販売する。オリジナルパッケージで個装し、契約企業のノベリティとしても活用していただく。本事業は今後、休耕田を活用し、米だけでなく，ブルーベリーの栽培やワインづくりなどにも拡大していく予定
② 地域資源の販路を生み出す「Farmer’s Market事業」
地域内で採れた野菜・お米などを、地域外でも販売できる仕掛けをつくる。現在、イベント販売に加え、企業のオフィスへ拡大している。これにより、三次市甲奴町のブランド価値を高めていく。
③ 甲奴町とつながる人材を増やす「現地研修」
①②で関わる企業の従業員の方を含め、都市部の方々に対して、甲奴町の暮らし・仕事体験プログラムを構築・提供する。これにより、甲奴町のファンを中長期的に増やしていくことを狙う。`,
  project_image: '/ogawamotors/project.jpg',
  about: `⼈⼝減と⾼齢化による担い⼿不⾜により、地域資源である⾷味に優れた⽶の栽培が衰退。休耕⽥の拡⼤が地域の魅⼒を低下させ、若年層が進学や就職を機に甲奴を離れる社会減が継続しており、⾼齢化による⾃然減と相まって⼈⼝減を加速させ、それが新
たな空き家の発⽣や地区の産業衰退につながる、という悪循環に陥っている。このことから、⼤正時代から存在する⼩川モータースは⾃動⾞販売・修理・ガソリンスタンドの事業を⾏う中で、お客様でもある地域の人々との対話からお困りごとを拾い上げ、「地域資源」を磨き直し多様な事業を⽣み出すことで、地域の担い⼿となる雇⽤を⽣み出す事業づくりを⾏なっている。`,
  video: 'https://www.youtube.com/embed/jYMjWyPJ9Qg?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
