import { CorpFurusatoRecruitment } from '~/_legacy/models';

import { CorpFurusatoRecruitmentData } from '~/data/CorpFurusatoRecruitmentData';

import CorpFurusatoAPI from './CorpFurusato';
import CorpFurusatoPresentationAPI from './CorpFurusatoPresentation';

const DATA = CorpFurusatoRecruitmentData.map((it) => new CorpFurusatoRecruitment(it));

export default class CorpFurusatoRecruitmentApi {
  // static async list() {
  //   const corpFurusato = await CorpFurusatoAPI.get(1, true);
  //   return DATA.map((d) => {
  //     d.corpFurusato = corpFurusato;
  //     return d;
  //   });
  // }

  static async get(id: number, simple = false) {
    const [corpFurusato, presentations] = await Promise.all([
      CorpFurusatoAPI.get(1, true),
      simple ? null : CorpFurusatoPresentationAPI.list(id),
    ]);
    const recruitment = DATA.find((d) => d.id == id);
    if (!recruitment) return;
    recruitment.corpFurusato = corpFurusato;
    if (!simple && presentations) recruitment.corpFurusatoPresentations = presentations;
    return recruitment;
  }
}
