import { CorpFurusato, CorpFurusatoPresentation } from './';

export default class CorpFurusatoRecruitment {
  constructor(public json: Record<string, any> = {}) {}

  id = this.json.id;
  title = this.json.title;
  cover = this.json.cover;
  corpFurusato = new CorpFurusato(this.json.corp_furusato);
  corpFurusatoPresentations = ((this.json.corp_furusato_presentations || []) as any[]).map(
    (p: any) => new CorpFurusatoPresentation(p),
  );
  link = this.json.link;
}
