import { User } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

import { User as UserAPI } from './';

export default class {
  /** @returns {Promise<User>} */
  static get() {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL('current_user'))
        .then((obj) => {
          resolve(new User(obj.user));
        })
        .catch(reject);
    });
  }

  static update(obj) {
    return new Promise((resolve, reject) => {
      const formData = UserAPI.createForm(obj);

      request
        .PATCH(apiURL('current_user'), formData)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
