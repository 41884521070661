import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/yohaku-bi/logo.png',
  cover: '/yohaku-bi/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/npo.yuki2008',
    },
  ],
  uid: 'yohaku-bi',
  category: '特定非営利活動法人',
  name: '湯来観光地域づくり公社（余白日FLAGS）',
  area: '中国地方',
  sdgs: [11, 15, 8],
  social_impacts: [
    {
      name: '教育',
      value: 0,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 4,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 2,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 1,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 1,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 1,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 2,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 20,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 20,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 100,
    },
  ],
  vision: '自然のチカラと共に、人生に豊かな余白日を。',
  mission: `余白日。それは日常でもない、非日常でもない、
その間の、今まで意識的には認識していなかった時間・空間のことです。
この余白日こそ、都市部に隣接した中山間地域の最大の可能性です。
私達は、余白日、を旗印に掲げ、余白日という概念が、地域や人の可能性を拓き、豊かな暮らしを生み、地域を次世代に継承する鍵であることを、
湯来町で体現するとともに、日本中の都市部隣接の中山間地域の活性化に貢献しています。`,
  tags: [
    {
      id: 1,
      name: '環境',
    },
    {
      id: 1,
      name: '地産地消',
    },
    {
      id: 1,
      name: 'まちづくり',
    },
    {
      id: 1,
      name: '地方創生',
    },
    {
      id: 1,
      name: '地域資源',
    },
  ],
  tag_text:
    '都市と合併した中山間地域。日本に数多く存在するこの地域は、合併後、行政の支援低下等の理由で産業の衰退と都市部への人口流出が加速。また、合併非選択の市町村に比べ独自性が打ち出せず、移住や企業進出等の面で選ばれにくい地域になっている。しかし、こうした地域が、都市の近接性を活かし、多様な主体との連携や、住民の主体性によって持続可能な地域へと変貌すれば、日本の未来を切り開くことができるのではないか。',
  message_title: `都市と合併した中山間地域ならではの可能性を`,
  message_text: `湯来町は、広島の奥座敷として栄え、今回のプロジェクト実施地である湯来温泉には、多いときには13の宿がありましたが、今では２軒のみで、旧温泉街では土日でもほとんど人が歩かないような状況になっています（新バイパス沿いは賑わっているが）。しかしながら、ここ数年で、新たなアウトドアアクティビティを造成したり、旧温泉街に貸切露天風呂やイタリアンバルなど、新たな目的地になり得るものを生み出していったことで、湯来町に対する注目の高まりを感じています。今後、今まで取り組めていなかった、企業のみなさんとの協働プログラムを始めることで、湯来町の可能性はさらに高まると感じています。都市部に近く、温泉と美しい自然があるこの湯来町を、一緒にさらに素敵な町にしていきませんか？`,
  activities: [
    {
      id: 1,
      title: 'アドベンチャーツーリズムを始めとした、体験型プログラムの開発・運営',
      text: 'シャワークライミング、テントサウナ、トレッキング、E-MTB等、人生観が変わるような体験の提供',
      image: '/yohaku-bi/activity1.jpg',
    },
    {
      id: 1,
      title: '特産品事業',
      text: '湯来特産品市場館の運営と、巻柿の生産やオオサンショウウオこんにゃくの販売支援',
      image: '/yohaku-bi/activity2.jpg',
    },
    {
      id: 1,
      title: '湯来交流体験センターの指定管理業務',
      text: '湯来町の観光の拠点施設の運営',
      image: '/yohaku-bi/activity3.jpg',
    },
    {
      id: 1,
      title: '温泉街再生事業',
      text: '貸切露天風呂の運営、湯治Bar sozoroの運営',
      image: '/yohaku-bi/activity4.jpg',
    },
    {
      id: 1,
      title: '地域限定旅行業',
      text: '旅行商品の開発・販売・運営',
      image: '/yohaku-bi/activity5.jpg',
    },
  ],
  owner_title: '理事長',
  owner: '佐藤 亮太',
  owner_image: '/yohaku-bi/owner.jpg',
  since: '2008/07/07',
  postal_code: '7388072',
  prefecture: '広島県',
  address: '広島市佐伯区湯来町多田2545',
  budget: '5,000万円~1億円',
  full_time_staff: '10~20',
  part_time_staff: '~10',
  volunteer: '~10',
  client: `中国運輸局、広島県、広島市、湯来町観光協会
スノーピークビジネスソリューションズ、モンベル
広島銀行、ゆうちょ銀行`,
  performance: `2008年　法人設立
2009年　湯来特産品市場館　運営開始
2010年　広島市立大学と協働し、「湯来ブランドマーク」を制作
2013年　自転車レース「湯来ヒルクライム」開始
2013年　江戸時代の古道を歩く歴史探訪ウォーキング「江戸の湯来を歩く」開始
2018年　理事長交代
2019年　広島市湯来交流体験センター指定管理業務を、広島市より受託
2019年　事業承継補助事業　採択
2019年　アドベンチャーツーリズム本格始動
2019年　湯来温泉街の露天風呂を再生させ、19年ぶりにオープン（クラウドファンディング活用）
2019年　湯来の特産品「巻柿」生産事業を、JAより事業承継
2020年　地域限定旅行業免許　取得
2020年　中国経済連合会と国土交通省が認定する「夢街道ルネサンス認定地区」に、当社が取組む「江戸の湯来を歩く」が認定。
2020年　中国5県休眠預金等活用コンソーシアムより、休眠預金活用の実行団体に採択
2020年　国土交通省「水の里の旅コンテスト2020」最優秀賞・インバウンド特別賞受賞
2020年　県立広島大学と協働し、「清流セラピー」実証実験
2021年　（株）スノーピークビジネスソリューションズと共同で、アウトドア型研修空間「CAMPING OFFICE HIROSHIMA」をオープン（県内初）
2021年　湯来温泉街の飲食店を再生させ、10年ぶりにオープン
2021年　中国運輸局「地域の観光コンテンツを活用したアドベンチャーツーリズムの
モデルツアー造成等事業」採択
2021年　オオサンショウウオ生態調査開始
2021年　湯来町への移住の足がかりとなる、空き家を活用したシェアハウス事業開始（予定）

その他、町内の小学校～高校での授業、県内の大学での講義等を行っている他、地元の子どもたち向けの体験プログラム等を実施している。
また、理事長の佐藤は、広島県の観光や福祉の複数の委員を務めた経験を持つ。`,
  locations: '/yohaku-bi/location.png',
  video: 'https://www.youtube.com/embed/nwO8Rn5Bmso?rel=0',
  video_thumbnail: 'https://img.youtube.com/vi/nwO8Rn5Bmso/0.jpg',
  documents: [
    {
      name: '特定非営利活動法人湯来観光地域づくり公社 令和2年度 事業報告書',
      url: 'https://drive.google.com/file/d/1SNnqsgvd4q96VVgt1U7Fi7ceGxbnRyBN/preview',
    },
    {
      name: 'パンフレット-表',
      url: 'https://drive.google.com/file/d/1PnhWOOGI9EutBgQIo12sTtnBI9130FI3/preview',
    },
    {
      name: 'パンフレット-裏',
      url: 'https://drive.google.com/file/d/1ZxZURz66bvRD-ZuZT6Y-0kEr3wtzOFOg/preview',
    },
  ],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/yohaku-bi/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
