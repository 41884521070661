import config from '~/config';

import { storage } from './';

type UserType = 'user' | 'org' | 'corporate';
export type AuthType = UserType | 'no-login';

type TokenInfo = {
  type: UserType;
  token: string;
};

function isTokenInfo(it: {}): it is TokenInfo {
  return 'type' in it && 'token' in it;
}

const AUTH_TOKEN_KEY = `_ichi_social_auth_token-${config.env}`;

export const authToken = {
  get(): TokenInfo | null {
    const maybeToken = storage.get(AUTH_TOKEN_KEY);
    if (!maybeToken) return null;

    if (typeof maybeToken === 'string') {
      const tokenInfo: TokenInfo = {
        // NOTE: tokenの頭文字がuserTypeを示す
        // refs: https://github.com/Rudolph-Miller/ichi-social/blame/main/app/controllers/api_controller.rb
        type: maybeToken[0] === 'O' ? 'org' : 'user',
        token: maybeToken,
      };
      return tokenInfo;
    }

    if (isTokenInfo(maybeToken)) {
      return maybeToken;
    }

    console.warn('token is invalid.', maybeToken);
    return null;
  },
  set(tokenInfo: TokenInfo) {
    storage.set(AUTH_TOKEN_KEY, tokenInfo);
  },
  delete() {
    storage.delete(AUTH_TOKEN_KEY);
  },
  getType(): AuthType {
    return this.get()?.type ?? 'no-login';
  },
};

export default authToken;
