import { useEffect } from 'react';

/**
 * ```ts
 * useAsyncEffect(async () => {
 *   // ↑ async関数が使える
 * }, []);
 * ```
 */
export function useAsyncEffect(fn: () => Promise<unknown>, deps?: unknown[]) {
  useEffect(() => {
    fn();
  }, deps);
}
