import queryString from 'query-string';

import { CorpFurusato, User } from '~/_legacy/models';

import { CorpFurusatoData } from '~/data/CorpFurusatoData';
import { request, apiURL, authToken } from '~/utils';

import OrgAPI from './Org';
import ReviewAPI from './Review';

export default class {
  static get(id, simple = false) {
    return new Promise((resolve, reject) => {
      const corpFurusato = new CorpFurusato(CorpFurusatoData);

      Promise.all([
        ReviewAPI.corpFurusato(),
        this.followers(1),
        new Promise((resolve) => {
          if (simple) {
            resolve();

            return;
          }

          Promise.all(
            ['nurseandcraft', 'sanken', 'yohaku-bi', 'ogawamotors', 'fuudo'].map((uid) =>
              OrgAPI.get(uid, true),
            ),
          ).then(resolve);
        }),
      ]).then(([reviews, members, organizations]) => {
        corpFurusato.reviews = reviews;

        corpFurusato.members = members;

        if (!simple) corpFurusato.corpFurusatoRelatings[0].organizations = organizations;

        resolve(corpFurusato);
      });
    });
  }

  static list() {
    return new Promise((resolve, reject) => {
      resolve([new CorpFurusato(CorpFurusatoData)]);
    });
  }

  static follow(id) {
    return new Promise((resolve, reject) => {
      request.POST(apiURL(`corp_furusatos/${id}/follow`)).then(() => {
        resolve();
      });
    });
  }

  static unfollow(id) {
    return new Promise((resolve, reject) => {
      request.DELETE(apiURL(`corp_furusatos/${id}/unfollow`)).then(() => {
        resolve();
      });
    });
  }

  static isFollowed(id) {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL(`corp_furusatos/${id}/is_followed`))
        .then((obj) => {
          resolve(obj.result);
        })
        .catch(reject);
    });
  }

  static followers(id) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`corp_furusatos/${id}/followers`))
        .then((obj) => {
          resolve(obj.users.map((u) => new User(u)));
        })
        .catch(reject);
    });
  }
}
