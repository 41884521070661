import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/hoshihara/logo.png',
  cover: '/hoshihara/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/HoshiharaYamanoGakkou',
    },
  ],
  uid: 'hoshihara',
  category: 'NPO法人',
  name: 'ほしはら山のがっこう',
  area: '中国地方',
  sdgs: [11, 4, 15],
  social_impacts: [
    {
      name: '教育',
      value: 2,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 3,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 3,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 2,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 0,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 0,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 0,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: 'NPO会員',
      value: 100,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 20,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 100,
    },
  ],
  vision:
    '「ふるさとを100年後の未来につなぐ」自然豊かで昔からの暮らしが今なお残る「ふるさと」の教育力・癒しの力・居場所としての機能・自然と人との共生力・農的な暮らしの知恵や農村景観などを活かしたさまざまな活動を通して，これからのふるさとづくりと未来を担う人づくりを推進することをもって，持続可能な地域社会づくりに寄与することを目的とする（定款より）。',
  mission: `自然豊かで昔からの暮らしが今なお残るわたしたちの「ふるさと」の豊かな価値や資源そして課題を、多様な主体が共有し、活性化または解決に向かえる仕組みを構築する`,
  tags: [
    {
      id: 1,
      name: 'ふるさと体験ができる場がない',
    },
    {
      id: 1,
      name: 'ふるさと資源が絶滅危機',
    },
    {
      id: 1,
      name: 'ふるさとをつなぐ人材不足',
    },
    {
      id: 1,
      name: 'ふるさと景観の悪化',
    },
    {
      id: 1,
      name: '田畑や里山の生き物を観察できる場がない',
    },
  ],
  tag_text:
    '「ふるさと」で暮らしたことがない人々も、ここ「ほしはら山のがっこう」に来ると「ふるさとを感じる、懐かしい、ホッとする」と話す。しかし自然と人が関わり合いながら暮らす「ふるさと」の風景や自然・文化・知恵や技術が、今失われようとしている。 100年後の子どもたちの「ふるさと」に行ってみたい、体験したい、という声をかなえるため、「ふるさと」の今が抱える課題を解決していく。',
  message_title: `自然のなかで自分らぁで工夫して遊ぶんで。どこに行っても生きられる人間になるけぇ。`,
  message_text: `「薪は１本じゃ燃えんのよ、焚き火を焚くようにやりんさい。
火を付け始めるときは、湿った薪じゃつかん。大きな薪もつかん。こまい薪があつまって、息をふきかけたらつく。

モノゴトもいっしょよう。

いったん燠ができりゃぁ、少々荒ましなことをしても燃える。
けどあんまり大きく焚きんさんな。人が寄らんようになる。火事になる。」

小さな失敗もケガも体験して、大きくなることが大事。`,
  activities: [
    {
      id: 1,
      title: '都市農村交流による地域づくり',
      text: '',
      image: '/hoshihara/activity1.jpg',
    },
    {
      id: 1,
      title: '体験フィールドづくり',
      text: '',
      image: '/hoshihara/activity2.jpg',
    },
    {
      id: 1,
      title: '地域資源の掘り起こしと調査保全・活性化',
      text: '',
      image: '/hoshihara/activity3.jpg',
    },
    {
      id: 1,
      title: '人材育成・ネットワークづくり納税促進事業',
      text: '',
      image: '/hoshihara/activity4.jpg',
    },
  ],
  owner_title: '理事長',
  owner: '檜谷義彦',
  owner_image: '/hoshihara/owner.jpg',
  since: '2021/01/31',
  postal_code: '7280624',
  prefecture: '広島県',
  address: '三次市上田町３８８',
  budget: '500万円＜1,000万円',
  full_time_staff: '10<20',
  part_time_staff: '10<20',
  volunteer: '100<500',
  client: ``,
  performance: `2003年文部科学大臣奨励賞（安藤スポーツ・食文化振興財団）／2011年内閣府特命大臣表彰子ども・若者育成支援（内閣府）／2019年度ひろしま環境賞（県）／2020年度RCCラブ・グリーン賞、あしたのまち・くらしづくり活動賞内閣官房長官賞／2021年環境保全功労者等表彰（環境省）`,
  locations: '/hoshihara/location.png',
  video: null,
  video_thumbnail: null,
  documents: [
    {
      name: '【履歴事項全部証明書】',
      url: 'https://drive.google.com/file/d/1xVpUd5SCckKvX3XDrLeL4PMZj8GAgOEc/preview',
    },
    {
      name: '第10回総会',
      url: 'https://drive.google.com/file/d/1SdwvkMKkTyIXOUzHCsWuqcVuLwco3tu5/preview',
    },
    {
      name: 'ほしはら山のがっこう事業紹介',
      url: 'https://drive.google.com/file/d/19f5WL_x5rBpzqzUszRIVqBCu49v5W0_p/preview',
    },
    {
      name: 'パンフレット',
      url: 'https://drive.google.com/file/d/1mJePM2m4HWLKwK6rNDjnuO8KvHYwzraH/preview',
    },
  ],
  images: ['/hoshihara/image1.jpg', '/hoshihara/image2.jpg'],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/hoshihara/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
