import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'minato0524',
  id: 13,
  total_amount: 2000000,
  title: `誰もが誰かのミカタになれる場所
ミカタカフェ`,
  issues: ['子どもの居場所', '学校と地域の協働', '地域資源を活かした教育', '子育て支援'],
  fund_uses: [
    {
      id: 1,
      title: '学びの機会を多様化する　1,400,000円',
      description: `学習スペースとしての運用を継続し、学習サポーター等の人材活用導入を目指します。また、体験的な学びの機会を増やすため、カフェ主催のイベントや、子どもたちの「やってみたい」を実現する回数を重ね、子どもたちが触れる学びの機会を多様化します。（費用使途：人件費/消耗品費)`,
      image: '/minato0524/use1.png',
    },
    {
      id: 1,
      title: '商品開発と食育の展開　300,000円',
      description: `島の食材を使った商品開発を継続。その過程を学びの機会として食育プログラム化し、アルバイトスタッフ以外の子どもにも開いていきます。食を通じて地域の大人と子どもの出会いを創出します。（費用使途：人件費/食材費/消耗品費/謝金）`,
      image: '/minato0524/use2.JPEG',
    },
    {
      id: 1,
      title: '仲間を集める　300,000円',
      description: `ゲスト講師の派遣や大学生のインターンシップ・フィールドワークを誘致し、子どもたちとの交流機会を創出します。（費用使途：広告宣伝費/旅費交通費/人件費/謝金/消耗品費）`,
      image: '/minato0524/use3.png',
    },
  ],
  purpose_title: '子どもの居場所をつくる、島の未来をつくる',
  purpose_text: `人口約7000人の大崎上島町。
”教育の島”を目指すこの離島に、全国から学生約700人が集まり、寮生活や一人暮らしをしています。
「島で学びたい。島で挑戦したい。」
そんな彼らの学びの意欲を、新型コロナウイルスの猛威が襲いました。学校は閉まり、島の行事は続々と中止。地域の大人との出会いを求めていた学生は、次第に部屋にこもるようになります。学校でも家でもない、子どもの居場所をつくる。子どもたちの挑戦を、地域の大人が応援できる場所にする。そんな目的をもってカフェ併設型コミュニティスペース・ミカタカフェを開きました。

コンセプトは「誰もが誰かのミカタになれる場所」。
地域の子どもたちの挑戦を、誰もが１杯のコーヒーから応援できる場所を目指しています。子どもたちが“味方”に出会える場所。色々な世界の“見方”を教えてもらえる場所。そんな場所になってほしいという二つの願いが、ミカタカフェの店名に込められています。`,
  purpose_image: '/minato0524/purpose.png',
  project_title: '学びが出会いをつくり、出会いが学びを生むサイクルへ',
  project_text: `1. 「学ぶ」を創る
小・中・高・高専生が気軽に立ち寄れる学習スペースを確保し、学校・学年を超えて放課後・休日の学習機会を創っています。都会で過ごす学生と同じように、「放課後ちょっとカフェに立ち寄って勉強しよう」ができる空間を提供します。コミュニティスペースでの自習は無料です。

2.「食べる」を創る
地域住民と子どもたちの交流拠点として、地域コミュニティに開いた高校生カフェを運営しています。カフェスペースで販売する大崎上島オリジナルドリップコーヒーは、高校生と地域おこし協力隊がクラウドファンディングで支援を募り開発しました。また、高校生のアルバイトスタッフが地域の素材を使ったスイーツを季節ごとに開発し、製作から販売までを担っています。

3. 「出会う」を創る
共に好奇心を深めてくれるようなオトナとの出会いを創り、「やりたかったこと」「チャレンジしたかったこと」を一つひとつ実現していくことで、なりたい自分になる方法を子ども達に伝えています。また、県内外を問わず、大学のフィールドワークやインターンを誘致し、活動拠点として利用してもらっています。離島の子どもたちにとって大学生は身近な存在ではありません。大人でも子どもでもない大学生の姿を見て、人生観・職業観を拡げてほしいと願っています。`,
  project_image: '/minato0524/project.png',
  about: `生徒数減少により統廃合の危機にあった島内唯一の県立普通科高校・大崎海星高校の魅力化をきっかけに、”地域で育てる”教育を実践し、学校と地域の垣根を越えて様々な事業を展開している団体です。『大崎海星高校魅力化プロジェクト』が取り組んできた教育実践が全国から注目され、書籍出版や国（文科省や経産省）からの複数の表彰の実績を土台として、取り組みを広島県全域に広げたり、教育と町づくりを行政以外の立場で推進したりすることを主目的としています。`,
  video: null,

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
