import { Review } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

import { ORGS } from './Org';

export default class {
  static create({ orgId, text, user, org, presentationId, image, corpFurusatoId }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const postableType = user ? 'User' : 'Organization';

      formData.append('review[postable_type]', postableType);
      formData.append('review[postable_id]', (user || org).id);
      formData.append('review[text]', text);

      if (image) formData.append('review[images][]', image);

      if (orgId) formData.append('review[organization_id]', orgId);

      if (presentationId) formData.append('review[presentation_id]', presentationId);

      if (corpFurusatoId) formData.append('review[corp_furusato_id]', corpFurusatoId);

      request
        .POST(apiURL('reviews'), formData)
        .then((obj) => {
          resolve();
        })
        .catch(reject);
    });
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      request
        .DELETE(apiURL(`reviews/${id}`))
        .then(resolve)
        .catch(reject);
    });
  }

  static corpFurusato() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('reviews/corp_furusato'))
        .then((obj) => {
          resolve(
            obj.reviews.map((r) => {
              const review = new Review(r);

              if (review.org) {
                const org = ORGS.find((o) => o.uid === review.org.uid);

                if (org) review.org = org;
              }

              if (review.postableOrg) {
                const org = ORGS.find((o) => o.uid === review.postableOrg.uid);

                if (org) review.postableOrg = org;
              }

              return review;
            }),
          );
        })
        .catch(reject);
    });
  }
}
