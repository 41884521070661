import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/nurse-and-craft/logo.png',
  cover: '/nurse-and-craft/cover.png',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/nurseandcraft',
    },
  ],
  uid: 'nurseandcraft',
  category: '合同会社',
  name: 'Nurse and Craft',
  area: '中国地方',
  sdgs: [3, 8, 11],
  social_impacts: [
    {
      name: '教育',
      value: 1,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 1,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 0,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 0,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 2,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 4,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 2,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 8,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 134,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 5,
    },
  ],
  vision: '「100年生きたら、おもしろかった」と誰もが言える世界の実現',
  mission: '人生100年時代の新しい暮らし方をつくり、それを支えるナースの精神を調整する',
  tags: [
    {
      id: 1,
      name: '医療介護問題',
    },
    {
      id: 1,
      name: '老々介護',
    },
  ],
  tag_text:
    '叶わない自宅死や増大する医療費、老老介護、地域の医療格差などの日本の医療介護問題と、変則的で不規則な勤務体制やで潜在看護師や出産や育児によるブランク、事故への不安・責任の重さなど医療介護従事者の問題の二つに対して、解決を目指しています。',
  message_title: `あなたが人生の最期に食べたいものは何ですか？`,
  message_text: `
私は10年以上介護業界に携わってきていますが、ずっと違和感を持っていました。それは、サービスを受ける側とサービスを提供する側がフェアではないということです。「大量に飲まなければならない薬」「通わせることが目的になっているリハビリ」「過剰なリスク管理での食事の提供」など疑問を抱くことが多々あります。

また、多くの人が自宅で最期を迎えたいと望みますが、現在、病院で亡くなる人が8割近くを占めています。これは先進各国の中でも突出して高い割合です。よく仲間内の会話で、「人生の最期に食べたいものは何？」と出てくると思いますが、果たしてどれだけの人がそれを叶えているのでしょうか？

私はどんなサービスも、人と人が対等、つまりフェアであることを重視します。医療も介護もそうあるべきです。ですから、私たちは人生の最後の最後まで自立して生きていきたいと考える方々のために、フェアな人間関係でサービスを提供したいと考えます。

代表社員　深澤裕之`,
  activities: [
    {
      id: 1,
      title: '■まちぐるみの介護部門',
      text: '遠く離れた家族や介護保険に極力頼らず、住民同士の互助とまちに点在する専門家（看護師やセラピスト、介護士等）によって見守られる地域での新しい暮らし方をつくります。地域にある空き家を、カフェや多世代共生型シェアハウス、デジタルクラフト工房などとしてリノベーションを行い、それらの場所にスタッフを点在させることで、住民の健康を日常の交流を通して見守ります。つまり、この部門では、住民を病気にさせないための予防と顔の見えるコミュニティづくり、もし病気や要介護状態になったとしても、住み慣れた自宅で暮らし続けられるように、医療・介護保険制度に基づいた訪問看護サービスを提供します。',
      image: '/nurse-and-craft/activity1.jpg',
    },
    {
      id: 1,
      title: '■クラフト部門',
      text: `この部門では、住民の日々の暮らしを支えるモノづくりの場「デジタルクラフト工房」を運営します。医療介護従事者が3Dプリンターなどのデジタル工作機械（デジタルファブリケーション）を用いて、ユーザーの身体状況と住環境に合わせた福祉用具や家具などの開発・製造をユーザー自身と一緒に行います。
将来は、全国の医療介護従事者のためのデジタルファブリケーション・スクールを開校し、大崎下島から世界に向けて医療介護職における“クリエイティブなデジタル職人”を育て、輩出していきたいと考えています。`,
      image: '/nurse-and-craft/activity2.jpg',
    },
    {
      id: 1,
      title: '■ビジネス開発部門',
      text: '島しょ部では、様々な資源が不足しています。よって、この部門では、「資源が足りない部分をテクノロジーで対処する」という考えに基づいて事業を開発していきます。現在、IoTを活用した在宅療養観察サービスやxR技術の応用によるトレーニング・リハビリテーションサービスなどの開発を進めており、テクノロジーを持つ外部の事業者との結びつきにより事業を拡大していきます。また、デジタルクラフト工房をラボとし、実証実験や研究調査の受託も行います。',
      image: '/nurse-and-craft/activity3.jpg',
    },
    {
      id: 1,
      title: '■ツーリズム部門',
      text: '過疎地における地域医療の“学び”と瀬戸内の自然を味わう“遊び”、島ならではの“文化”が体験できる医療介護従事者のための研修ツーリズムをつくります。私たちは、過疎地における持続可能な地域医療の成功事例となり、それを実践的に学ぶ場として全国の医療介護従事者の皆様に提供します。',
      image: '/nurse-and-craft/activity4.jpg',
    },
  ],
  recruitments: [],
  owner_title: '代表社員',
  owner: '深澤 裕之',
  owner_image: '/nurse-and-craft/owner.jpg',
  since: '2019/8/30',
  postal_code: '7340304',
  prefecture: '広島県',
  address: '呉市豊町久比302-2',
  budget: '3,000万円<5,000万円',
  full_time_staff: '~10',
  part_time_staff: '~10',
  volunteer: '50~100',
  client: `呉信用金庫
広島市信用組合
日本政策金融公庫`,
  performance: `2019年11月 公益財団法人アクティブベースくれ「第21回地域活性化支援制度『幸運』助成金」受賞, 2019年12月 呉市「起業家支援プロジェクト」採択`,
  locations: '/nurse-and-craft/location.png',
  images: ['/nurse-and-craft/image1.jpg', '/nurse-and-craft/image2.jpg'],
  documents: [
    {
      name: 'パンフレット',
      url: 'https://drive.google.com/file/d/1DnsrA0YRbK_8Xa5nI5lOX-aoQaPSNpTg/preview',
    },
    {
      name: '会社概要',
      url: 'https://drive.google.com/file/d/1-H71CES-8aw0rEMXx_J8RKzCbno1eEl2/preview',
    },
  ],

  // Sample below
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  video: 'https://www.youtube.com/embed/B_vPDduCzlY?rel=0',
  video_thumbnail: 'https://img.youtube.com/vi/B_vPDduCzlY/0.jpg',
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/sanken/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
