import { SocialIssueT } from '~/state/socialIssue';

import { Org } from './';

export default class SocialIssue {
  constructor(public json: SocialIssueT = {} as SocialIssueT) {}

  id = this.json.id;
  type = this.json.type;
  name = this.json.name;
  displayName = this.json.display_name;
  image = this.json.image;
  color = this.json.color;
  fontColor = this.json.font_color;
  title = this.json.title;
  description = this.json.description;
  overview = this.json.overview;
  cover = this.json.cover;
  sdgs = this.json.sdgs || [];
  tags = this.json.tags || [];
  about = this.json.about;
  aboutImage = this.json.about_image;
  aboutImageNotice = this.json.about_image_notice;
  aboutTitle = this.json.about_title;
  aboutText = this.json.about_text;
  whyTitle = this.json.why_title;
  whyImage = this.json.why_image;
  whyImageNotice = this.json.why_image_notice;
  whyText = this.json.why_text;
  stakeholderTitle = this.json.stakeholder_title;
  stakeholderText = this.json.stakeholder_text;
  problemTitle = this.json.problem_title;
  problemText = this.json.problem_text;
  solutionTitle = this.json.solution_title;
  solutionText = this.json.solution_text;
  articles = this.json.articles || [];
  goodImage = this.json.good_image;
  goodText = this.json.good_text;
  badImage = this.json.bad_image;
  socialIssueProblems = this.json.social_issue_problems || [];
  others = this.json.others
    ? typeof this.json.others === 'string'
      ? (this.json.others as string).split(',')
      : this.json.others
    : [];
  orgs = (this.json.organizations || []).map((o) => new Org(o));
  population = this.json.population;
  area = this.json.area;
  density = this.json.density;
  link = this.json.link;
  linkTitle = this.json.link_title;
  problems = this.json.problems;
  sdgsScore = this.json.sdgs_score || [];

  category() {
    return this.type === 'GeneralSocialIssue' ? '社会課題' : '地域課題';
  }
}
