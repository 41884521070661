import { isWeb } from '~/utils/for-next/constants';

type Env = 'production' | 'development' | 'staging';
const env = process.env.REACT_APP_ENV as Env;

function defineConfig<T>(setting: Record<Env, T>): T {
  return setting[env];
}

const apiUrl =
  process.env.API_URL ||
  process.env.NEXT_PUBLIC_DEV_API_URL ||
  defineConfig({
    development: 'http://localhost:3000',
    staging: 'https://ichi-social-stg.herokuapp.com',
    production: 'https://ichi-social-prd.herokuapp.com',
  });

const api_root = `${new URL(apiUrl).origin}/api`;

const postcode = defineConfig({
  development: 'jB9CX4E5w2Fb5g7AAeJLC2YZWjf1PNHqg0FJCkT',
  staging: 'jB9CX4E5w2Fb5g7AAeJLC2YZWjf1PNHqg0FJCkT',
  production: 'lqKLvgeBDHLUaB1ss8aGGplUKkETsNE7KW56HhS',
});

if (isWeb) {
  if (env === 'production') {
    console.info('version:', process.env.VERSION);
  } else {
    console.info('ENV:', env);
    console.info('api_root:', api_root);
  }
}

const config = {
  api_root,
  env,
  postcode,
};

export default config;
