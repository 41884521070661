import { isSSR } from './constants';

/**
 * SSR時(node.js)に`window`や`document`(`window.document`)などは存在しないため、それらを安全に使うための関数
 * @example
 * ```ts
 * // ssr時はnullになる
 * const TWTTR = safeWindow((window) => window.twttr);
 * ```
 */
export function safeWindow(): Window | undefined;
export function safeWindow<T>(fn: (_: Window) => T): T | undefined;
export function safeWindow<T>(fn?: (_: Window) => T): Window | T | undefined {
  if (isSSR) return;
  return fn?.(window) ?? window;
}
