import toast from 'react-hot-toast';
import { ResponseError } from 'superagent';

import { UnauthorizedError } from '~/api/_utils/request';

export default function alertResponseError(error: Error): false {
  if (error instanceof UnauthorizedError) {
    toast.error('認証エラーです。ログイン状態を確認してください。');
    return false;
  }
  if (error.message) {
    toast.error(error.message);
    return false;
  }

  const e = error as ResponseError;

  if (e.response && e.response.text) {
    try {
      const j = JSON.parse(e.response.text);

      if (j.error) {
        toast.error(j.error);
      } else {
        toast.error(e.response.text);
      }
    } catch (_) {
      toast.error(e.response.text);
    }
  } else {
    toast.error(JSON.stringify(e));
  }
  return false;
}
