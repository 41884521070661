import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'sanken',
  id: 2,
  total_amount: 2300000,
  title: '三段峡の自然を調べ・伝え・未来へつなげる 「三段峡野外博物館プロジェクト」',
  issues: ['環境教育', '地域資源', '集落の小規模化・高齢化', '希薄する地域コミュニティ'],
  fund_uses: [
    {
      id: 1,
      title: '地域の自然を守る人を育てる　800,000円',
      description: `・フィールドの調査研究費（400,000円）
調査のための備品購入、①オオサンショウウオ：マイクロチップ・捕獲用の備品・水中調査用のウエットスーツ等　②ゴマシジミ：電動のこぎり、双眼鏡、カメラ　③哺乳類：巣箱、赤外線カメラ、マイクロスコープ

・人件費・交通費 消耗品費

・さんけん自然塾（400,000円）講師謝礼（自然分野の専門家）企画運営費 消耗品費（キャンプ体験用の貸し出しテント等、ガサガサ用網等）`,
      image: '/sanken/use1.jpg',
    },
    {
      id: 1,
      title: '自然に新しい価値を付け加える仲間をうみだす　1,500,000円',
      description: `・手作りビジターセンターの設置費（リフォーム・展示物作成）（1,200,000円）建築資材費（リフォーム用・材木等）展示備品（標本箱・棚・パーテーション）交通費

・燃料費　レンタカー代（運搬用軽トラック）人件費

・WEB三段峡野外博物館の作成準備（300,000円）謝礼(調査員・カメラマン)交通費。`,
      image: '/sanken/use2-2.JPG',
    },
  ],
  purpose_title: '都市と田舎の”あたらしい”関係づくり',
  purpose_text: `三段峡を天然の野外博物館と見立て、人づくり、地域づくりの場としての新しい価値を生み出すプロジェクトです。環境を守るこれからのリーダーと、自然の恵みを活かした仕事や暮らし方を作り出す人を育てます。

●自然を守り続けるための「人」を育てる
多世代の子供と、自然分野の専門家が一緒になって自然で遊び、学ぶ活動をつくります。三段峡を自然を愛し、環境を守ることができるリーダー人材が育つフィールドにします。

●自然に新しい価値を付け加える「仲間」をうみだす
自然を守り豊かに暮らす生き方や仕事を一緒に作り上げていくために、だれでも里山のグリーンインフラ（自然環境の多様な機能）にアクセスできる環境を整備します。`,
  purpose_image: '/sanken/purpose.jpeg',
  project_title: '自然の持つ価値が、100年先も暮らし続けられる地域をつくる。',
  project_text: `【人材育成】
フィールドの調査研究
オオサンショウウオをはじめ、フィールドの調査を専門家と子供達みんなで実施し、データの蓄積と調査研究方法を次世代に受け継ぎます。

さんけん自然塾
中学生が講師になって、小学生に「自然の感動」を伝えるプログラムです。「こども同士で学び合う」をコンセプトに子供たちが自然に対する関心を高めるキッカケを提供します。

【グリーンインフラの整備】
手作りビジターセンターの設置
大学生や地域住民と協働し、フィールドに訪れた人が、自然に対する理解の促進と、自然との関わり方を考える場所（ビジターセンター）を三段峡の入り口につくります。またWEB上の三段峡博物館設置のための資料収集をします。`,
  project_image: '/sanken/project.jpg',
  about: `安芸太田町の三段峡はダムによる電源開発など人と自然の狭間に苦しむ歴史や、広島県で最悪の人口減少率・高齢化率をもつ過疎の最も厳しい町でもあります。三段峡-太田川流域研究会は、かつて観光地として栄え、文化財保護法・自然公園法で保護され、景観や生物多様性が保証された希少なフィールドである「特別名勝三段峡」をモデルに、環境保全と歴史文化の伝承・教育人材育成に取り組んでいる。`,
  video: 'https://www.youtube.com/embed/b5U5_XQKCSM?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
