import { Org } from './';

export default class CorpFurusatoPresentation {
  constructor(public json?: Record<string, any>) {}

  orgUid = this.json?.org_uid;
  id = this.json?.id;
  organization = new Org(this.json?.organization);
  video = this.json?.video;
  closeDate = this.json?.close_date;
  totalAmount = this.json?.total_amount;
  currentAmount = this.json?.current_amount;
  title = this.json?.title;
  purposeImage = this.json?.purpose_image;
  purposeTitle = this.json?.purpose_title;
  purposeText = this.json?.purpose_text;
  projectImage = this.json?.project_image;
  projectTitle = this.json?.project_title;
  projectText = this.json?.project_text;
  about = this.json?.about;
  fundUses = (this.json?.fund_uses || []) as any[];
  issues = this.json?.issues;

  percentage() {
    return Math.floor((this.currentAmount / this.totalAmount) * 100);
  }
}
