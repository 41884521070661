import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/fuudo/logo.png',
  cover: '/fuudo/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/fuudo2510',
    },
  ],
  uid: 'fuudo',
  category: '一般社団法人',
  name: 'フウド',
  area: '中国地方',
  sdgs: [11, 14, 4],
  social_impacts: [
    {
      name: '教育',
      value: 2,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 4,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 2,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 1,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 1,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 1,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 6,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 80,
    },
  ],
  vision:
    '【瀬戸内海を代表する地域密着型ソーシャルベンチャー】  地域の社会課題解決と地域資源の価値創造によるソーシャルベンチャーを実践し、瀬戸内地方を代表する地域密着型企業を目指します。',
  mission: `【誇れる風土を次世代に】 地域活性化事業を軸に、瀬戸内の人、自然、文化、歴史、産業など、私たちの誇れる「風土」を、今以上に磨きをかけて子どもたちや若者など次世代につないでいきます。`,
  tags: [
    {
      id: 1,
      name: '環境',
    },
    {
      id: 1,
      name: '教育',
    },
    {
      id: 1,
      name: '地方創生',
    },
    {
      id: 1,
      name: '地域資源',
    },
    {
      id: 1,
      name: '海洋保全',
    },
  ],
  tag_text:
    '移住定住促進（地域にとって魅力ある人材や企業の受入促進）／空き家の利活用促進／海洋プラスチック問題・子どもや若者たちへの海洋教育／地域のブランディング＆プロモーション',
  message_title: `「なんてきれいで豊かな海なんだろう」`,
  message_text: `江田島市に訪れて最初に感じた印象でした。広島で生まれ育ち、千葉・東京でしばらく過ごしていた自分にとって、故郷のすぐ近くにこれほど美しく豊かな海が広がっていることは衝撃的でした。まもなくして江田島市に移り住み、日常的に家族や友人たちと海に接するようになりました。海で泳いだり、釣りをしたり、マリンスポーツをしたり、磯観察をしたり、イベントをしたり。いつの間にかきれいで豊かな海が身近になり、日々の暮らしと仕事の一部となっていました。
その反面、海洋ゴミや生態系の変化、子どもたちの海離れ、海難事故など、海に関わる様々な社会課題にも気づくことができました。わたしたちの大好きな身近な海を、いまより良い形で次世代につないでいきたい。本プロジェクトがこの思いを実現するきっかけになれば幸いです。ご協力のほど、よろしくお願いいたします。`,
  activities: [
    {
      id: 1,
      title: 'えたじま未来の海づくり大作戦',
      text: ' ビーチクリーンや海洋ゴミ問題に関する調査、子ども達への里海体験プログラム・イベントの企画実施など、海洋環境や海洋教育に関する取組みを行っており、それらの活動の財源確保として、新電力の代理販売事業「えたじまでんき」（電力使用量の一部が海の活動費として還元される）に取り組んでいます。 ▼参考：えたじま未来の海づくり大作戦 https://etajima-umi.net/ ３年後には、海の拠点づくり事業「渚の交番プロジェクト（日本財団）」への申請を目標に、関係団体と協議を進めており、このたびの事業をきっかけに念願の拠点づくりを実現させてたいと考えています。 ▼参考：日本財団「渚の交番プロジェクト」 https://www.nippon-foundation.or.jp/what/projects/policeoffice',
      image: '/fuudo/activity1.JPG',
    },
    {
      id: 1,
      title: '移住交流促進事業',
      text: '江田島市の移住交流の拠点としてコミュニティスペース（コワーキングスペース）を運営し、移住相談対応及び空き家の紹介業務を行っています。県庁地域力創造課の地域コーディネーターとして県主催の移住セミナー等へ登壇しています。  ',
      image: '/fuudo/activity2.png',
    },
    {
      id: 1,
      title: 'サテライトオフィス誘致事業',
      text: '首都圏企業をメインターゲットに、江田島市へのサテライトオフィス（あるいは本社機能）の誘致を江田島市役所と一緒に取り組んでいます。 IT分野の企業をはじめ、これまでに３社を誘致しており、雇用促進とそれにともなう移住者増、企業のCSRによる地域貢献などの効果も表れています。  ',
      image: '/fuudo/activity3.jpg',
    },
    {
      id: 1,
      title: 'ふるさと納税促進事業',
      text: '江田島市のふるさと納税の寄附促進を図るため、事業者及び返礼品の登録を増やし、より効果的な情報の発信、効率的な受発注システムの運用などを行っています。昨年度から着手し、過年度比2倍の寄附を集めることに成功しています。 ',
      image: '/fuudo/activity4.jpg',
    },
    {
      id: 1,
      title: '観光プロダクト創出事業',
      text: '島の人、自然、文化、産業、歴史などに関する様々なプログラムを体験できるイベント「えたじまものがたり博覧会」の企画運営、島の食材を集めて食べるサイクリングツアー「Otsukai！」の企画運営、小中高校生を対象としたSDGｓプログラムの企画実施、瀬戸内ワーケーション事業（現在立ち上げ準備中）など、地域の資源を活用した観光プロダクトを生み出し、地域観光の活性化に取り組んでいます。',
      image: '/fuudo/activity5.png',
    },
  ],
  owner_title: '理事長',
  owner: '後藤 峻',
  owner_image: '/fuudo/owner.jpg',
  since: '2018/11/30',
  postal_code: '7372312',
  prefecture: '広島県',
  address: '江田島市沖美町畑997-2',
  budget: '1,000万円＜3,000万円',
  full_time_staff: '~10',
  part_time_staff: '~10',
  volunteer: '~10',
  client: `広島県、江田島市、広島銀行`,
  performance: `未回答`,
  locations: '/fuudo/location.png',
  video: 'https://www.youtube.com/embed/X6JjJRABNXo?rel=0',
  video_thumbnail: 'https://img.youtube.com/vi/X6JjJRABNXo/0.jpg',
  documents: [],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/fuudo/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
