import type * as Models from '~/_legacy/models';

import { Org as OrgAPI } from './';

export default class ProjectResultAPI {
  static async list() {
    const orgs: Models.Org[] = await OrgAPI.list();

    const it = [...RESULT_DATA] as ProjectResultData[];
    it.forEach((r) => {
      if (r.votedOrgUid) {
        r.votedOrg = orgs.find((o) => o.uid === r.votedOrgUid) || orgs[0];
      }
    });

    return it;
  }
}

export type ProjectResultData = {
  category: string;
  image: string;
  title: string;
  votedTitle: string;
  votedTheme?: {
    id?: number;
    category: string;
    title: string;
    color: string;
  };
  data: Array<{
    name: string;
    value: number;
    color: string;
  }>;
  votedOrgUid?: string;
  votedOrg?: Models.Org;
};

export const RESULT_DATA: Array<Omit<ProjectResultData, 'votedOrg'>> = [
  {
    category: 'テーマ投票',
    image: '/images/theme-voting@2x.png',
    title: 'ご関心のあるテーマについて',
    votedTitle: '1st CHOICE',
    votedTheme: {
      category: '働き方改革',
      title: '働き方改革とワークライフバランス',
      color: 'rgb(235, 255, 167)',
    },
    data: [
      { name: '20代', value: 14, color: '#095174' },
      { name: '40代', value: 14, color: '#67c392' },
      { name: '30代', value: 10, color: '#bbdee4' },
      { name: '50代', value: 5, color: '#abd16e' },
      { name: '60代', value: 1, color: '#d2e0a2' },
    ],
  },
  {
    category: 'テーマ投票',
    image: '/images/theme-voting@2x.png',
    title: 'ご関心のあるテーマについて',
    votedTitle: '2nd CHOICE',
    votedTheme: {
      id: 1,
      category: '自然環境と生態系',
      title: '気候変動',
      color: 'rgb(167, 255, 211)',
    },
    data: [
      { name: '20代', value: 7, color: '#095174' },
      { name: '30代', value: 7, color: '#bbdee4' },
      { name: '40代', value: 5, color: '#67c392' },
      { name: '50代', value: 3, color: '#abd16e' },
      { name: '60代', value: 2, color: '#d2e0a2' },
      { name: '70代', value: 1, color: '#efc998' },
    ],
  },
  {
    category: 'テーマ投票',
    image: '/images/theme-voting@2x.png',
    title: 'ご関心のあるテーマについて',
    votedTitle: '3rd CHOICE',
    votedTheme: {
      id: 1,
      category: '貧困と格差',
      title: '経済格差の拡大',
      color: 'rgb(221, 167, 255)',
    },
    data: [
      { name: '20代', value: 10, color: '#095174' },
      { name: '30代', value: 6, color: '#bbdee4' },
      { name: '40代', value: 6, color: '#67c392' },
      { name: '50代', value: 1, color: '#abd16e' },
    ],
  },
  {
    category: 'わくわく寄付',
    image: '/images/theme-voting@2x.png',
    title: '「生物多様性」をテーマにしたわくわく寄付',
    votedTitle: 'MOST VOTED',
    votedOrgUid: 'moridukuri',
    data: [
      { name: '20代', value: 7, color: '#095174' },
      { name: '30代', value: 7, color: '#bbdee4' },
      { name: '40代', value: 6, color: '#67c392' },
      { name: '50代', value: 2, color: '#abd16e' },
      { name: '60代', value: 1, color: '#d2e0a2' },
      { name: '70代', value: 1, color: '#efc998' },
    ],
  },
  {
    category: 'わくわく寄付',
    image: '/images/theme-voting@2x.png',
    title: '「沖縄県」をテーマにしたわくわく寄付',
    votedTitle: 'MOST VOTED',
    votedOrgUid: 'la-vida',
    data: [
      { name: '30代', value: 12, color: '#bbdee4' },
      { name: '20代', value: 10, color: '#095174' },
      { name: '40代', value: 7, color: '#67c392' },
      { name: '50代', value: 3, color: '#abd16e' },
      { name: '60代', value: 2, color: '#d2e0a2' },
    ],
  },
  {
    category: 'わくわく寄付',
    image: '/images/theme-voting@2x.png',
    title: '「若者の孤独」をテーマにしたわくわく寄付',
    votedTitle: 'MOST VOTED',
    votedOrgUid: 'with us',
    data: [
      { name: '40代', value: 13, color: '#67c392' },
      { name: '20代', value: 10, color: '#095174' },
      { name: '30代', value: 6, color: '#bbdee4' },
      { name: '50代', value: 4, color: '#abd16e' },
      { name: '60代', value: 3, color: '#d2e0a2' },
      { name: '70代', value: 1, color: '#efc998' },
    ],
  },
  {
    category: 'わくわく寄付',
    image: '/images/theme-voting@2x.png',
    title: '「教育格差」をテーマにしたわくわく寄付',
    votedTitle: 'MOST VOTED',
    votedOrgUid: 'tokai-tsubame',
    data: [
      { name: '40代', value: 9, color: '#67c392' },
      { name: '30代', value: 7, color: '#bbdee4' },
      { name: '20代', value: 5, color: '#095174' },
      { name: '50代', value: 3, color: '#abd16e' },
      { name: '60代', value: 3, color: '#d2e0a2' },
      { name: '70代', value: 2, color: '#efc998' },
    ],
  },
  {
    category: 'わくわく寄付',
    image: '/images/theme-voting@2x.png',
    title: '「ひとり親世帯」をテーマにしたわくわく寄付',
    votedTitle: 'MOST VOTED',
    votedOrgUid: 'sagazaitaku',
    data: [
      { name: '20代', value: 8, color: '#095174' },
      { name: '40代', value: 8, color: '#67c392' },
      { name: '30代', value: 7, color: '#bbdee4' },
      { name: '50代', value: 1, color: '#abd16e' },
      { name: '60代', value: 1, color: '#d2e0a2' },
    ],
  },
];
