import { User, Org } from './';

type Json = {
  id: unknown;
  holdable_user: User['json'];
  holdable_organization: Org['json'];
};

export default class RoomMember {
  constructor(public json?: Json) {}

  id = this.json?.id;
  holdableUser = this.json?.holdable_user && new User(this.json.holdable_user);
  holdableOrg = this.json?.holdable_organization && new Org(this.json.holdable_organization);

  holdable(): User | Org {
    return (this.holdableUser || this.holdableOrg) as User | Org;
  }
}
