import { User, Review, CorpFurusatoRelating } from './';

export default class CorpFurusato {
  constructor(public json?: Record<string, any>) {}

  id = this.json?.id;
  cover = this.json?.cover;
  logo = this.json?.logo;
  municipality = this.json?.municipality;
  area = this.json?.area;
  name = this.json?.name;
  description = this.json?.description;
  plan = this.json?.plan;
  planUrl = this.json?.plan_url;
  reviews = ((this.json?.reviews || []) as any[]).map((r) => new Review(r));
  admins = ((this.json?.admins || []) as any[]).map((a) => new User(a));
  members = ((this.json?.members || []) as any[]).map((m) => new User(m));
  corpFurusatoRelatings = ((this.json?.corp_furusato_relatings || []) as any[]).map(
    (c) => new CorpFurusatoRelating(c),
  );
  corporates = [];
}
