import { PropsWithChildren } from 'react';
import { RecoilRoot } from 'recoil';

import { SusnetToaster } from '~/components/_wrapper/SusnetToaster';

/** next(pages dir)とstorybookの両方で指定される */
export function AppContexts(props: PropsWithChildren) {
  return (
    <RecoilRoot>
      {props.children}
      <SusnetToaster />
    </RecoilRoot>
  );
}
