import { ApiPresentation, ApiPresentationQuestion } from '~/api/v1/presentations/all_index/get';

import { Org, Review } from './';

interface PresentationJson extends ApiPresentation, Record<string, any> {
  presentation_questions?: ApiPresentationQuestion[];
}

export default class Presentation {
  constructor(public json?: PresentationJson) {}

  id = this.json?.id;
  thumbnail = this.json?.thumbnail;
  video = this.json?.video;
  competitionId = this.json?.competition_id;
  organization = new Org(this.json?.organization as any);
  title = this.json?.title;
  description = this.json?.description;
  slide = this.json?.slide;
  slideVideo = this.json?.slide_video;
  reviews = ((this.json?.reviews || []) as any[]).map((r) => new Review(r));
  questions = this.json?.presentation_questions || [];
  votes = this.json?.votes || 0;
  comments = this.json?.comments || 0;
  voted = this.json?.voted;
  anyVoted = this.json?.any_voted;
  votable = this.json?.votable;
  status = this.json?.status;
  links = (this.json?.presentation_links || []) as any[];
}
