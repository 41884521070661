type Json = {
  name: string;
  value: number;
  image: string;
  image_disabled: string;
};

export default class SocialImpact {
  constructor(public json: Json) {}

  name = this.json.name;
  value = this.json.value;
  image = this.json.image;
  imageDisabled = this.json.image_disabled;
}
