import { User } from '~/_legacy/models';

import { request, apiURL, authToken } from '~/utils';

class UserAPI {
  static create(obj) {
    return new Promise((resolve, reject) => {
      const formData = UserAPI.createForm(obj);

      request

        .POST(apiURL('users'), formData)
        .then(({ token }) => {
          authToken.set({ token, type: 'user' });

          resolve();
        })
        .catch(reject);
    });
  }

  static get(id) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`users/${id}`))
        .then((obj) => {
          resolve(new User(obj.user));
        })
        .catch(reject);
    });
  }

  // static sendResetPasswordEmail({ email }) {
  //   return new Promise((resolve, reject) => {
  //     const formData = new FormData();

  //     formData.append('email', email);

  //     request
  //
  //       .POST(apiURL('users/send_reset_password_email'), formData)
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }

  static createForm(obj) {
    const formData = new FormData();

    const birth = `${obj.birthYear}/${obj.birthMonth}/${obj.birthDate}`;

    if (obj.avatar !== null && typeof obj.avatar === 'object')
      formData.append('user[avatar]', obj.avatar);
    if (obj.email && obj.email.length > 0) formData.append('user[email]', obj.email);
    if (obj.password && obj.password.length > 0) formData.append('user[password]', obj.password);
    formData.append('user[name]', obj.name);
    formData.append('user[nickname]', obj.nickname);
    formData.append('user[birth]', birth);
    formData.append('user[gender]', obj.gender);
    formData.append('user[area]', obj.area);
    formData.append('user[title]', obj.title);
    formData.append('user[private_name]', obj.privateName);
    formData.append('user[private_birth]', obj.privateBirth);

    return formData;
  }
}

export default UserAPI;
