import { RoomMember, Message } from './';

type Json = {
  id: unknown;
  room_members: any[];
  messages: any[];
  is_read: boolean;
};

export default class Room {
  constructor(public json?: Json) {}

  id = this.json?.id;
  roomMembers = ((this.json?.room_members || []) as any[]).map((rm) => new RoomMember(rm));
  messages = ((this.json?.messages || []) as any[]).map((m) => new Message(m));
  isRead = this.json?.is_read;

  members() {
    return this.roomMembers.map((rm) => rm.holdable());
  }

  talkTo(user: { id: unknown }) {
    const res = this.members().find((u) => u.id !== user.id);

    if (res) return res;

    return this.members()[0];
  }
}
