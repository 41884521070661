import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'sanken',
  id: 7,
  total_amount: 2000000,
  title: '自然の学びを、生きるチカラに。\n～三段峡は体験と学びの野外博物館～',
  issues: ['環境教育', '地域資源', '環境保全（生物多様性）', '希薄する地域コミュニティ'],
  fund_uses: [
    {
      id: 1,
      title: '広島の自然を守る人を育てる。 (700,000円)',
      description: `中学生が講師になる、自然塾の運営
      「こども同士が学び合う」をコンセプトに、自然体験教室を企画運営します。ご寄付は、子供たちの体験のための備品や、運営のための人件費に使わせていただきます。
      `,
      image: '/sanken-2022/use1.JPG',
    },
    {
      id: 1,
      title: '地域の自然を、伝える。 (700,000円)',
      description: `多様な年代に対しての環境教育活動の実施や、自然を学び伝える動画発信やWEBサイトを運営します。ご寄付は、運営のための経費や人件費に使わせて頂きます。`,
      image: '/sanken-2022/use2.jpg',
    },
    {
      id: 1,
      title: '地域の自然を守る技術を受け継ぐ。 (600,000円)',
      description: `フィールドの調査や保全活動を実施し、地域の環境を守りながら、フィールワーカーたちの技術を継承します。取り組みには小学生から大学生まで参加し、多世代にわたって地域の自然を守る取り組みをします。ご寄付は、フィールドワーカーへの謝礼や、調査保全のための備品の購入、運営のための人件費に使わせていただきます。`,
      image: '/sanken-2022/use3.JPG',
    },
  ],
  purpose_title: 'これからの広島の自然を守る人を育てる。',
  purpose_text: `「わしらが絶滅危惧種じゃ」
  　広島県の鳥類を調べる、上野吉雄さんは言います。上野さんは長年地域の自然を調べ、守り続ける、フィールドワーカーを務めてきました。地域の自然の変化を察知し、何が絶滅の危険があるのかを調査します。しかし、彼らの役割を引き継ぐ後継者がいないのです。

  　その大きな原因に、子供達の自然離れがあります。自然の中で学び、遊ぶ機会がどんどん少なくなっています。地域の環境を守る次世代の育成は喫緊の課題です。

  「里山の自然が都市と田舎を、もう一度結びつける」
  　私たちは中山間地域の自然を活かした人材育成に、地域や都市、企業など多様なステークホルダーが協力をして、広島の環境を担うリーダーを育てる取り組みを続けています。`,
  purpose_image: '/sanken-2022/purpose.JPG',
  project_title: '自然のコトを、自分ゴトにできる人になる。',
  project_text: `
  ●広島の自然を守るリーダーを育てる
  地域の環境を守るために、行動し発信できる人材を育てるのは、私たちの使命です。子供達がプログラムを作り講師役を務め、お互いが学び合い、成長し合う場をつくります。

  ●すべての子供に自然からの学びを
  都市に住む子供も、田舎に住む子供も、自然の中で遊ぶ機会が減っています。家庭の事情や住んでいる地域に関係なく、子供達へ自然の学びを届けるために活動します。

  ●フィールドワーカーの知恵と技術を次の世代へ
  地域の自然を調べ、守るフィールドワーカーと呼ばれる人がいます。高齢化と人材不足により、彼等もまた「絶滅危惧種」になっています。彼らの知恵と技術を次世代に引き継ぎます。`,
  project_image: '/sanken-2022/project.JPG',
  about: `安芸太田町の三段峡はダムによる電源開発など人と自然の狭間に苦しむ歴史や、広島県で最悪の人口減少率・高齢化率をもつ過疎の最も厳しい町でもあります。三段峡-太田川流域研究会は、かつて観光地として栄え、文化財保護法・自然公園法で保護され、景観や生物多様性が保証された希少なフィールドである「特別名勝三段峡」をモデルに、環境保全と歴史文化の伝承・教育人材育成に取り組んでいる。`,
  video: 'https://www.youtube.com/embed/b5U5_XQKCSM?rel=0',

  // Sample below
  close_date: '2022/12/15',
  current_amount: 1330000,
});

export default presentation;
