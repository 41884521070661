import { Corporate } from './';

export default class ThemeComp {
  constructor(public json: Json) {}

  id = this.json.id;
  votingThemes = this.json.voting_themes || [];
  corporate = this.json.corporate && new Corporate(this.json.corporate as any);
  votable = this.json.votable;
}

type Json = {
  id: number;
  votable: null;
  voting_themes: VotingTheme[];
  corporate: {
    id: number;
    uid: string;
    logo: string;
    cover: string;
    materiality: string;
    name: string;
    title: string;
    sdgs: number[];
    category: string;
    category_postfix: boolean;
    business: string;
    social_vision: string;
    interest: string;
    homepage: string;
  };
};

type VotingTheme = {
  id: number;
  category: string;
  title: string;
  tags: string[];
  color: string;
  votes: number;
  voted: null;
};
