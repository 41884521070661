import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/minato0524/logo.jpg',
  cover: '/minato0524/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/profile.php?id=100063670740213',
    },
  ],
  uid: 'minato0524',
  category: '一般社団法人',
  name: 'まなびのみなと',
  area: '広島県',
  sdgs: [11, 4, 15],
  social_impacts: [
    {
      name: '教育',
      value: 5,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 3,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 0,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 0,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 0,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 2,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織メンバー',
      value: 15,
    },
    {
      id: 1,
      name: 'うち学生メンバー',
      value: 3,
    },
  ],
  vision: `「誰もが学びに出会う日常を」
”学び”は、学校の中はもちろん、仕事や趣味、家庭、それらに関わる人間関係など、あらゆる日常の中に当たり前に存在しています。私たちは、誰もが自分なりの学びに出会い（気付き）、一人ひとりがその学びを深めていくような社会の実現を目指します。`,
  mission: `①『誰もが立ち寄る”まなび”の中継地点になる』
私たちは、「学ぶ人たちが行き交う港」をつくります。その場には、所属や価値観、年齢、学ぶ意欲の有無に関わらず、多種多様な方々が集まり、そこに価値が生まれると、私たちは信じています。そのために「人と人」「人と学び」「学びと学び」を中継し、それぞれの学びを深められる対話の機会を提供します。

②『すべての人の”まなび”の帆を開く』
帆は推進力を生むものであるとともに、シンボル（意志の象徴）でもあります。帆を開くことで、船は意志を持って前進することができます。私たちは、関わってくれた方々が、各々の中に”学びの帆”を開き、それぞれの学びへと進んでいくきっかけを作ることをミッションとしています。`,
  tags: [
    {
      id: 1,
      name: '子供達の居場所づくり',
    },
    {
      id: 1,
      name: '地域教育',
    },
  ],
  tag_text: `”学び”は学校以外でも、さまざまな場所に存在しています。しかしながら学校を一歩出ると、”学び”を得る機会があっても、それを仲間と探究し深め合う機会は少なくなります。
私たちは業を通じて、まず誰しもが”学び”を得れる機会を作ります。そして仲間と探究し深め合う居場所づくりを、学校の枠を超えたコミュニティで展開していきます。そこから、すべての人が学びを実践できる社会を作り、未来の大人たちに手渡していきます。`,
  message_title: `教育を通じた持続可能な町づくりへの挑戦　〜ミカタカフェが繋ぐ学校と社会〜`,
  message_text: `人口減少社会において、地方は消滅してしまうのでしょうか。学校が統廃合し、祭りの継承が危ぶまれ、事業承継ができず無くなる地域企業。そのような地方において一般社団法人まなびのみなとは、大崎上島を中心として教育を通じた「ひとづくり」を行い、新たな「まちの対流」を生み出すことに挑戦しています。教育の島を目指す大崎上島町には、人口約7000人の離島に、11の教育機関と、1000人を超える中高生がいます。そこで行政と民間、学校と地域、地元民と移住者、田舎と都会、子供と大人…　それらを異質”を対流させ、人々に新たな流れを生み、まちの代謝を上げて、未来へと繋いでいく。そのための事業として、私たちが運営するコミュニティスペース『ミカタカフェ』があります。だれもがだれかの”味方”になる場所。新たな出会いによって”見方”を変える。偶発的な出会いの場を生み出すことで、私たちのビジョンである”誰もが学びに出会える日常”を創っていき、豊かな地域資源を循環させ、日々の学びと暮らしの中に、Will-beingが溢れる地域づくりを目指しています。

一般社団法人まなびのみなと　代表理事　取釜 宏行`,
  activities: [
    {
      id: 1,
      title: '「ミカタカフェ事業」',
      text: `小・中・高・高専生が気軽に立ち寄れる学習スペースを確保し、学校・年齢を超えた放課後・休日の居場所づくりを行っています。
また、地域住民と子どもたちが偶発的に出会える交流拠点となるよう、地域コミュニティに開けたカフェも隣接し、多種多様な出会いの場の創出を目指しています。
カフェスペースの売り上げは居場所全体の運営費・維持費として活用します。1杯のコーヒーでオトナが子どもたちを応援できるように、誰もが誰かのミカタになれる場所になるようにと願いを込め、ミカタカフェと名付けました。`,
      image: '/minato0524/activity1.jpg',
    },
    {
      id: 1,
      title: 'マイプロジェクトアワード',
      text: `マイプロジェクトとは、「主体性」をもって、つくりたい未来に向けて「アクション」を行っていく、学びのプロセスです。そのプロセスを通して、自分自身の興味関心の発見や、他者との協働、社会の価値発見・創造に向かう姿勢を育むことを目指します。広島県内で高校生の探究学習・プロジェクト学習の動きを盛り上げていくことを目指し、2021年度より認定NPO法人カタリバとの連携を開始しました。1万人を超える高校生が参加する全国高校生マイプロジェクトアワード、通称マイプロアワードの広島県Summitの実施・運営を担います。`,
      image: '/minato0524/activity2.jpg',
    },
    {
      id: 1,
      title: 'SCHシンポジウム西日本',
      text: `SCHとは、「Super Community High School」の略で、高校、行政、民間のセクターを超えて、高校と地域の連携に取り組むネットワークづくりを指します。東北芸術工科大学で開かれるSCHに参加した大崎海星高校の生徒が、西日本でもこの取り組みを実施したいと、2018年からSCH 西日本を立ち上げました。
西日本で高校と地域の連携のネットワークを広げていきたいと、 毎年1回シンポジウムを開催しています。
      `,
      image: '/minato0524/activity3.jpeg',
    },
    {
      id: 1,
      title: '教育情報化コーディネーター',
      text: `国のGIGAスクール構想をうけて、2021年度から大崎上島町にある小学校3校および中学校1校に一人一台のタブレットPCが導入されました。まなびのみなとは「教育情報化コーディネーター」事業を大崎上島町から委託を受け、一人一台のタブレットPCを活用した授業実施支援および、大崎上島町における教育情報化推進計画の作成支援を実施しています。また、大崎海星高校においてもICT活用の推進および小中学校の情報教育と接続を図るためのコーディネート業務を実施しています。これから必要とされる情報活用能力の育成および「主体的・対話的で深い学び」を実現し、大崎上島町の子どもたちがVUCA時代を自分たちで切り拓いていけるよう、各校でのICT活用の支援をしています。`,
      image: '/minato0524/activity4.jpg',
    },
  ],
  owner_title: '代表理事',
  owner: '取釜 宏行',
  owner_image: '/minato0524/owner.jpg',
  since: '2019/05/24',
  postal_code: '7250301',
  prefecture: '広島県',
  address: '豊田郡大崎上島町中野1871番地1',
  budget: '1,500万円＜3,000万円',
  full_time_staff: '10<20',
  part_time_staff: '10<20',
  volunteer: 'なし',
  client: `もみじ銀行
日本財団
NPO法人カタリバ`,
  performance: `・カフェ併設型コミュニティスペース「ミカタカフェ」の運営
・全国高校生マイプロジェクトアワード広島県Summitの運営協力
・SCH(Super Community High school)シンポジウム西日本の企画・運営
・大崎上島町教育情報化コーディネーター委嘱
・株式会社JTB企画の”瀬戸内SDGsツアー”における現地の高校生フィールドワークの受け入れ
・”高校生デザインプロジェクト”における観光マップ作成教材開発・コーディネート
・島外からゲストを迎える対話会・学びの島カフェウルトラマラソンを計21回実施
・教育ドキュメンタリー映画Most Likely to Succeed上映・対話会の企画・実施
・大崎上島町・株式会社WE共催の教育プログラム”島スクール”の現地コーディネート
・文部科学省よりCS（コミュニティスクール）マイスター委嘱
・SCH(Super Community High school)シンポジウム西日本の企画・運営`,
  locations: '/minato0524/location.png',
  video: null,
  video_thumbnail: null,
  documents: [],
  images: ['/minato0524/image1.jpeg', '/minato0524/image2.jpg'],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/minato0524/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
