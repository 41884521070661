import { Org } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

import { Org as OrgAPI } from './';

export default class {
  static get() {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL('current_organization'))
        .then((obj) => {
          resolve(new Org(obj.organization));
        })
        .catch(reject);
    });
  }

  static update(obj) {
    return new Promise((resolve, reject) => {
      const formData = OrgAPI.createForm(obj);

      request
        .PATCH(apiURL('current_organization'), formData)
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
