import { usePathname } from 'next/navigation';
import { ReactElement, useEffect } from 'react';

import config from '~/config';

export default (props: { children: ReactElement }) => {
  const pathname = usePathname();

  const initializeGA = (measurementId: string) => {
    let script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
    script1.async = true;
    document.body.appendChild(script1);

    let script2 = document.createElement('script');
    script2.text = `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${measurementId}');`;
    document.body.appendChild(script2);
  };

  const trackingId = 'G-Y1K9HQ7TFB';

  useEffect(() => {
    if (config.env !== 'production') return;
    initializeGA(trackingId);
  }, []);

  useEffect(() => {
    if (!(window as any).gtag) return;
    if (!trackingId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
      );
      return;
    }
    (window as any).gtag('config', trackingId, { page_path: pathname });
  }, [pathname]);

  return props.children;
};
