import { CSSProperties } from 'react';
import { Toaster } from 'react-hot-toast';

import { COLOR_BACKGROUND_WHITE } from '~/colors/common';

import SuccessIcon from '@public/icons/success.svg';
import WarningIcon from '@public/icons/warning.svg';

const SvgStyle = {
  flexShrink: 0,
} satisfies CSSProperties;

const ToastStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  fontWeight: 'bold',
} satisfies CSSProperties;

export function SusnetToaster() {
  return (
    <Toaster
      position='bottom-right'
      toastOptions={{
        duration: 5000,
        style: ToastStyle,
        success: {
          style: {
            background: '#d1e7dd',
            borderColor: '#A3CFBB',
            color: '#146C43',
          },
          icon: <SuccessIcon style={SvgStyle} />,
        },
        error: {
          style: {
            background: '#F8D7DA',
            borderColor: '#F1AEB5',
            color: '#B02A37',
            fontWeight: 'bold',
          },
          icon: <WarningIcon style={SvgStyle} />,
        },
        loading: {
          style: {
            background: COLOR_BACKGROUND_WHITE,
            borderColor: COLOR_BACKGROUND_WHITE,
          },
        },
      }}
    />
  );
}
