import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'fuudo',
  id: 5,
  total_amount: 2000000,
  title: 'えたじま未来の海づくり大作戦',
  issues: ['海洋保全', '地域資源', '環境課題'],
  fund_uses: [
    {
      id: 1,
      title: '「えたじま渚の学校」事業・活動の強化に係る運転資金　1,500,000円',
      description: `海の団体や行政機関との調整、協議体の運営、HPやSNSを通じた情報発信、海岸清掃や里海体験イベントの企画運営、電気を使って海を守る「えたじまでんき」の普及などに係る人件費及び専門家謝金、視察経費に活用する。`,
      image: '/fuudo/use1.jpg',
    },
    {
      id: 1,
      title: '海の情報プラットフォームの構築　500,000円',
      description: `本プロジェクト「えたじま未来の海づくり大作戦」のHP、SNSを再整備し、「えたじま渚の学校」の実現に向けた情報のプラットフォームを構築する。`,
      image: '/fuudo/use2.jpg',
    },
  ],
  purpose_title: '身近な海を次世代につなぐ',
  purpose_text: `私たちが暮らす広島県江田島市。広島湾に浮かぶこの島の周辺の海は美しく、瀬戸内海系の多くの生き物が生息しており、牡蠣養殖をはじめ様々な産業のよりどころとなっています。一方で、海洋ゴミの大量発生や貧栄養化による生物の減少、温暖化による生態系の変化、子どもたちの海離れ、海難事故などの社会課題も顕在化しています。こういった社会課題に向き合い、私たちの大好きな身近な海を、今より良い形で次世代に引き継ぐための活動基盤の強化を本プロジェクトの目的とし、これらの活動を通じた将来的として、海を通じて人を育てる「えたじま渚の学校」の設立を目指します。`,
  purpose_image: '/fuudo/purpose.jpg',
  project_title: '海を知る、学ぶ、感じる、学ぶための仕組みづくり',
  project_text: `海岸清掃、里海体験イベント、SUP・カヌー体験スクール、ライフセービング講習など、江田島市では当社も含め、海の団体が様々な活動に取り組んでいます。また、これらの活動を支援するために、電気料金の一部を海に還元する「えたじまでんき」という電力販売事業を当社でスタートしました。

「海を守る」「海を楽しむ」「海から学ぶ」。各団体が取り組んでいるこれらの取組みを体系化し、海を通じて人を育てる「えたじま渚の学校」をつくることを目的にこれまで関係者で協議してきました。本プロジェクトでは「えたじま渚の学校」の実現に向けて、組織づくり、資金確保の仕組みづくり（えたじまでんきの普及）、情報プラットフォーム構築（HP等メディア制作・発信）に取り組みます。`,
  project_image: '/fuudo/project.jpg',
  about: `「地域の誇れる風土を次世代につなぐ」をミッションに、地域密着型のまちづくり会社として2018年11月に一般社団法人フウドは設立しました。広島湾に浮かぶ江田島・能美島（江田島市）を拠点に、コミュニティスペースの運営、移住支援・空き家の利活用、企業誘致、地域資源を活用した観光コンテンツの企画運営、そして海洋環境・海洋教育へのアクションなど、地域内外の人や企業の交流を通じて持続可能なまちづくりを目指しています。`,
  video: 'https://www.youtube.com/embed/pVlaNexSpVM?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
