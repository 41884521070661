import { CompetitionT } from '~/state/competition';

import { Corporate, SocialIssue } from './';

export default class Competition {
  constructor(public json: CompetitionT & Record<string, unknown> = {} as any) {}

  id = this.json.id;
  cover = this.json.cover;
  corporate = this.json.corporate && new Corporate(this.json.corporate);
  socialIssue = this.json.social_issue && new SocialIssue(this.json.social_issue);
  amount = this.json.amount;
  schedules = this.json.schedules;
  status = this.json.status;
  voteCountVisible = this.json.vote_count_visible;
  _title = this.json.title;

  statusString() {
    switch (this.status) {
      case 'open':
        return 'SPO団体\n募集中';
      case 'voting':
        return '投票\n期間中';
      case 'finished':
        return '投票\n終了';
      default:
        return '';
    }
  }

  title() {
    if (this._title) return this._title;

    if (this.socialIssue.type === 'CitySocialIssue') {
      return `「${this.socialIssue.name}」で活動する寄付先を募集します。`;
    }

    return `「${this.socialIssue.name}」をテーマにした寄付先となる協力団体を${
      this.socialIssue.type === 'GeneralSocialIssue' ? '広く全国から' : ''
    }募集します。`;
  }
}
