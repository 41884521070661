import queryString from 'query-string';

import { Org, Org2, Review, User, Competition } from '~/_legacy/models';

import { request, apiURL, authToken } from '~/utils';

import CorpFurusatoRecruitmentAPI from './CorpFurusatoRecruitment';
import Fuudo from './Org2/fuudo';
import Hoshihara from './Org2/hoshihara';
import Minato0524 from './Org2/minato0524';
import NurseAndCraft from './Org2/nurse-and-craft';
import OgawaMotors from './Org2/ogawamotors';
import Okuroumori from './Org2/okuroumori';
import Sanken from './Org2/sanken';
import YohakuBi from './Org2/yohaku-bi';

export const ORGS = [
  NurseAndCraft,
  Sanken,
  YohakuBi,
  OgawaMotors,
  Fuudo,
  Hoshihara,
  Okuroumori,
  Minato0524,
];
const NEW_ORGS = [Hoshihara, Okuroumori, Minato0524];

class OrgAPI {
  static list(isIndex, { sdgs, tags, areas } = {}) {
    return new Promise((resolve, reject) => {
      const base = apiURL('organizations');
      const query = queryString.stringify({
        'sdgs[]': sdgs,
        'tags[]': tags,
        'areas[]': areas,
      });
      const path = `${base}?${query}`;

      request

        .GET(path, { is_index: isIndex })
        .then((obj) => {
          resolve(
            obj.organizations.map((org) => {
              let o = ORGS.find((o) => o.uid === org.uid);

              if (o) {
                o = Object.assign(new Org2(), o);
                o.id = org.id;
                return o;
              }

              return new Org(org);
            }),
          );
        })
        .catch(reject);
    });
  }

  static get(uid, simple = false) {
    return new Promise((resolve, reject) => {
      let org = ORGS.find((o) => o.uid === uid);

      if (org) {
        org = Object.assign(new Org2(), org);

        Promise.all([
          request.GET(apiURL(`organizations/${uid}`)),
          request.GET(apiURL(`organizations/${uid}/reviews`)),
          this.followers(uid),
          this.demographics(uid),
          this.competitions(uid),
          new Promise((resolve) => {
            if (simple) {
              resolve();

              return;
            }

            CorpFurusatoRecruitmentAPI.get(1, true).then(resolve);
          }),
          new Promise((resolve) => {
            if (simple) {
              resolve();

              return;
            }

            CorpFurusatoRecruitmentAPI.get(2, true).then(resolve);
          }),
        ])
          .then(
            ([
              orgObj,
              reviewObj,
              followers,
              demographics,
              competitions,
              corpFurusatoRecruitment1,
              corpFurusatoRecruitment2,
            ]) => {
              org.id = orgObj.organization.id;

              org.reviews = reviewObj.reviews.map((r) => {
                const review = new Review(r);

                review.org = org;

                return review;
              });

              this.setReviewOrg(org);

              org.followerCount = followers.length;
              org.followers = followers;

              org.demographics = demographics;

              org.competitions = competitions;

              if (!simple) {
                if (NEW_ORGS.findIndex((o) => o.uid === org.uid) > -1) {
                  org.corpFurusatoRecruitments = [corpFurusatoRecruitment2];
                } else {
                  org.corpFurusatoRecruitments = [
                    corpFurusatoRecruitment2,
                    corpFurusatoRecruitment1,
                  ];
                }
              }

              resolve(org);
            },
          )
          .catch(reject);

        return;
      }

      request

        .GET(apiURL(`organizations/${uid}`))
        .then((obj) => {
          const o = new Org(obj.organization);

          this.setReviewOrg(o);

          resolve(o);
        })
        .catch(reject);
    });
  }

  static setReviewOrg(o) {
    const { reviews } = o;

    o.reviews = reviews.map((r) => {
      if (r.postableOrg) {
        const org = ORGS.find((o) => o.uid === r.postableOrg.uid);

        if (org) {
          r.postableOrg = org;
        }
      }

      return r;
    });
  }

  static create(obj) {
    return new Promise((resolve, reject) => {
      const formData = OrgAPI.createForm(obj);

      request

        .POST(apiURL('organizations'), formData)
        .then(({ token }) => {
          authToken.set({ token, type: 'org' });

          resolve();
        })
        .catch(reject);
    });
  }

  static checkUid(uid) {
    return new Promise((resolve) => {
      const formData = new FormData();

      formData.append('uid', uid);

      request

        .POST(apiURL('organizations/check_uid'), formData)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  // static sendResetPasswordEmail({ email }) {
  //   return new Promise((resolve, reject) => {
  //     const formData = new FormData();

  //     formData.append('email', email);

  //     request
  //
  //       .POST(apiURL('organizations/send_reset_password_email'), formData)
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }

  static follow(uid) {
    return new Promise((resolve, reject) => {
      request.POST(apiURL(`organizations/${uid}/follow`)).then(() => {
        resolve();
      });
    });
  }

  static unfollow(uid) {
    return new Promise((resolve, reject) => {
      request.DELETE(apiURL(`organizations/${uid}/unfollow`)).then(() => {
        resolve();
      });
    });
  }

  static isFollowed(uid) {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL(`organizations/${uid}/is_followed`))
        .then((obj) => {
          resolve(obj.result);
        })
        .catch(reject);
    });
  }

  static followers(uid) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`organizations/${uid}/followers`))
        .then((obj) => {
          resolve(obj.users.map((u) => new User(u)));
        })
        .catch(reject);
    });
  }

  static demographics(uid) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`organizations/${uid}/demographics`))
        .then((obj) => {
          resolve(obj);
        })
        .catch(reject);
    });
  }

  static competitions(uid) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`organizations/${uid}/competitions`))
        .then((obj) => {
          resolve(obj.competitions.map((c) => new Competition(c)));
        })
        .catch(reject);
    });
  }

  /**
   * @param { Record<string, any> & { socialIssues: Array<import("~/state/socialIssue").SocialIssueT> } } obj
   */
  static createForm(obj) {
    const formData = new FormData();

    const since = `${obj.year}/${obj.month}/${obj.date}`;

    if (obj.logo !== null && typeof obj.logo === 'object')
      formData.append('organization[logo]', obj.logo);
    if (obj.cover !== null && typeof obj.cover === 'object')
      formData.append('organization[cover]', obj.cover);
    if (obj.email && obj.email.length > 0) formData.append('organization[email]', obj.email);
    if (obj.password && obj.password.length > 0)
      formData.append('organization[password]', obj.password);
    formData.append('organization[category]', obj.category);
    formData.append('organization[name]', obj.name);
    formData.append('organization[owner]', obj.owner);
    formData.append('organization[owner_title]', obj.ownerTitle);
    if (obj.ownerImage !== null && typeof obj.ownerImage === 'object')
      formData.append('organization[owner_image]', obj.ownerImage);
    formData.append('organization[area]', obj.area);
    (obj.sdgs || []).map((sdg) => formData.append('organization[sdgs][]', sdg));
    (obj.tags || []).map((tag) => formData.append('organization[tags][]', tag.name));
    formData.append('organization[vision]', obj.vision);
    formData.append('organization[mission]', obj.mission);
    obj.links.map((link, i) => {
      formData.append(`organization[links][${i}][name]`, link.name);
      formData.append(`organization[links][${i}][url]`, link.url);
      return 0;
    });
    formData.append('organization[social_issue]', obj.socialIssue);
    if (obj.activityImage !== null && typeof obj.activityImage === 'object')
      formData.append('organization[activity_image]', obj.activityImage);
    formData.append('organization[activity]', obj.activity);
    formData.append('organization[performance]', obj.performance);
    formData.append('organization[since]', since);
    formData.append('organization[postal_code]', obj.postalCode);
    formData.append('organization[prefecture]', obj.prefecture);
    formData.append('organization[address]', obj.address);
    formData.append('organization[budget]', obj.budget);
    formData.append('organization[full_time_staff]', obj.fullTimeStaff);
    formData.append('organization[part_time_staff]', obj.partTimeStaff);
    formData.append('organization[volunteer]', obj.volunteer);
    formData.append('organization[client]', obj.client);
    formData.append('organization[uid]', obj.uid);

    obj.socialIssues?.forEach((it, i) => {
      formData.append(`organization[social_issues][][name]`, it.name);
    });

    if (obj.survey) {
      /** @type {import("~/_legacy/components/forms/FormOrgSurvey").OrgSurvey} */
      const survey = obj.survey;
      formData.append('survey[how_did_you_know]', JSON.stringify(survey.how_did_you_know));
      formData.append('survey[purpose]', JSON.stringify(survey.purpose));
      if (survey.event_date) {
        const { year, month, day } = survey.event_date;
        formData.append('survey[event_date]', [year, month, day].join('/'));
      }
      const keys = [
        'agency',
        'spo_group',
        'company_or_municipality',
        'other_for_how_did_you_know',
        'other_for_purpose',
      ];
      keys.forEach((key) => {
        if (survey[key]) {
          formData.append(`survey[${key}]`, survey[key]);
        }
      });
    }

    return formData;
  }
}

export default OrgAPI;
