import { OrgT } from '~/state/org';

import * as Models from './';

type Json = Partial<OrgT> & {
  locations?: any[];
  tag_text?: string;
  follower_count?: number;
  followers?: any[];
  video?: any;
  video_thumbnail?: any;
  activities?: any[];
  message_title?: any;
  message_text?: any;
  recruitments?: any[];
  badges?: any[];
  demographics?: any[];
  social_impacts?: Models.SocialImpact['json'][];
  community_members?: any[];
  images?: string[];
  competitions?: any[];
  // social_issues?: any[];
  corp_furusato_recruitments?: any[];
  documents?: any[];
};

type IOrg = Omit<Models.Org, 'type' | 'json' | 'socialIssue' | 'activityImage' | 'activity'>;

export default class Org2 implements IOrg {
  constructor(public json?: Json) {}

  id = this.json?.id;
  type = this.json?.type;
  uid = this.json?.uid;
  email = this.json?.email;
  name = this.json?.name;
  category = this.json?.category;
  logo = this.json?.logo;
  area = this.json?.area;
  cover = this.json?.cover;
  locations = this.json?.locations; // Add
  tags = this.json?.tags || [];
  tagText = this.json?.tag_text; // Add
  sdgs = this.json?.sdgs || [];
  followerCount = this.json?.follower_count; // Add
  followers = this.json?.followers || []; // Add
  video = this.json?.video; // Add
  videoThumbnail = this.json?.video_thumbnail; // Add
  activities = this.json?.activities || []; // Add
  owner = this.json?.owner;
  ownerTitle = this.json?.owner_title;
  ownerImage = this.json?.owner_image;
  messageTitle = this.json?.message_title; // Add
  messageText = this.json?.message_text; // Add
  mission = this.json?.mission;
  links = (this.json?.links || []).map((link) => new Models.Link(link));
  recruitments = this.json?.recruitments || []; // Add
  badges = this.json?.badges || []; // Add
  vision = this.json?.vision;
  since = this.json?.since;
  reviews = (this.json?.reviews || []).map((review) => new Models.Review(review as any));
  postalCode = this.json?.postal_code;
  prefecture = this.json?.prefecture;
  address = this.json?.address;
  budget = this.json?.budget || '';
  fullTimeStaff = this.json?.full_time_staff;
  partTimeStaff = this.json?.part_time_staff;
  volunteer = this.json?.volunteer;
  client = this.json?.client || '';
  performance = this.json?.performance || '';
  demographics = this.json?.demographics || [];
  socialImpacts = (this.json?.social_impacts || []).map((si) => new Models.SocialImpact(si)); // Add
  communityMembers = this.json?.community_members || []; // Add
  images = this.json?.images || [];
  competitions = (this.json?.competitions || []).map((c) => new Models.Competition(c)); // Add
  corpFurusatoRecruitments = (this.json?.corp_furusato_recruitments || []).map(
    (r) => new Models.CorpFurusatoRecruitment(r),
  ); // Add
  documents = this.json?.documents || []; // Add

  socialIssues = this.json?.social_issues;
  reports = this.json?.reports || [];
  presentations = (this.json?.presentations || []).map((p) => new Models.Presentation(p));
  files = this.json?.files || [];

  displayPostalCode() {
    if (!this.postalCode) return '';
    if (this.postalCode.length !== 7) return this.postalCode;

    return this.postalCode.slice(0, 3) + '-' + this.postalCode.slice(3, 7);
  }

  image() {
    return this.logo;
  }
}
