import { css } from 'styled-components';

const large = 1919;
const small = 1520;
export const TABLET = 768;

export const MID = 1000;
const pc = 1200;

const media = {
  tabletSize: `(max-width: ${small - 1}px) and (min-width: ${TABLET}px)`,
  spSize: `(max-width: ${TABLET - 1}px)`,
  large: (...args) => css`
    @media screen and (min-width: ${large}px) {
      ${css(...args)};
    }
  `,
  small: (...args) => css`
    @media screen and (max-width: ${large - 1}px) and (min-width: ${small}px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (max-width: ${small - 1}px) and (min-width: ${TABLET}px) {
      ${css(...args)};
    }
  `,
  sp: (...args) => css`
    @media (max-width: ${TABLET - 1}px) {
      ${css(...args)};
    }
  `,
  notLarge: (...args) => css`
    @media screen and (max-width: ${large}px) {
      ${css(...args)};
    }
  `,
  tabletAndSp: (...args) => css`
    @media screen and (max-width: ${small - 1}px) {
      ${css(...args)};
    }
  `,
  mid: (...args) => css`
    @media screen and (max-width: ${MID - 1}px) {
      ${css(...args)};
    }
  `,
  tbMin: (...args) => css`
    @media screen and (min-width: ${TABLET}px) {
      ${css(...args)};
    }
  `,
  midMin: (...args) => css`
    @media screen and (min-width: ${MID}px) {
      ${css(...args)};
    }
  `,
  pcMin: (...args) => css`
    @media screen and (min-width: ${pc}px) {
      ${css(...args)};
    }
  `,
};

export default media;
