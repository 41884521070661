import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'ogawamotors',
  id: 9,
  total_amount: 2000000,
  title: 'みんなが受け継げる、“ふるさと甲奴”',
  issues: ['集落の小規模化・高齢化', '耕作放棄地', '地域資源の活用', '雇用の創出'],
  fund_uses: [
    {
      id: 1,
      title: '現地研修、スマイル10アール事業に係る運営費、広報費、謝金 (1,500,000円)',
      description: `・甲奴の暮らし体験プログラムである現地研修の運営費・交通費(1,000,000)
・スマイル10アール等の事業広報(500,000)
・地域拠点整備（古民家改修）`,
      image: '/ogawamotors-2022/use1.jpg',
    },
    {
      id: 1,
      title: `地域貢献事業に関わる仲間を集める (500,000円)`,
      description: `連携する大学生が、活動内容の報告や広報に取り組み、企業を含めたより多くの仲間を集められる体制を整えるために使わせていただきます。
・HP・SNSでの情報発信
・学生の交通費・宿泊費
・備品消耗品費`,
      image: '/ogawamotors-2022/use2.jpg',
    },
  ],
  purpose_title: '地域のお困りごとを解決が、地域の担い手づくりにつながる',
  purpose_text: `人口減と高齢化による担い手不足により、地域資源である食味に優れた米の栽培が衰退。休耕田の拡大が地域の魅力を低下させ、若年層が進学や就職を機に甲奴を離れる社会減が継続しており、高齢化による自然減と相まって人口減を加速させ、それが新たな空き家の発生や地区の産業衰退につながる、という悪循環に陥っています。

このことから、大正時代から存在する小川モータースは地域内のお困りごとを拾い上げ、地域の資源を活かした、多様な事業を生み出す活動を行なってきました。今後は、地域外から研修や授業にこられる大学生とともに、スモールビジネスに取り組み、地域資源を受け継ぐ担い手を育てます。`,
  purpose_image: '/ogawamotors-2022/purpose.jpg',
  project_title: 'ふるさとを感じてもらう” 持続可能な仕事 ”をみんなでうみだす',
  project_text: `大学と連携し、甲奴のスモールビジネスを創出
-地域不在の世代が、地域の外からから見た「資源」とは-

① 持続可能な農業をつくる「スマイル10アール事業」
農家からお米を当社が買い取り、都市部の企業と契約をして、お米を販売する。オリジナルパッケージで個装し、契約企業のノベリティとしても活用していただいています。
現在行っている休耕田を活用したブルーベリーの栽培や、ワイン用のぶどうづくりなども、今後は学生と共に、加工品やワインなどの製品化に向け取り組んでいく予定です。製品化や販路についても、学生の斬新な発想をカタチにできるように協同して取組みます。

②甲奴町とつながる人材を増やす「現地研修」
休耕田を活用する「スマイル10アール事業」だけでなく、関心の高い学生には、空き家のリノベーションや他の地域事業の立ち上げに携わっていただき、実際にそれぞれのアイディアで地域で事業を起こしてもらいます。

①②で関わる大学生の方を含め、都市部の方々に対して、甲奴町の暮らし・仕事体験プログラムを構築・提供します。これにより、甲奴町のファンを中長期的に増やしていくことを狙います。`,
  project_image: '/ogawamotors-2022/project.jpg',
  about: `⼈⼝減と⾼齢化による担い⼿不⾜により、地域資源である⾷味に優れた⽶の栽培が衰退。休耕⽥の拡⼤が地域の魅⼒を低下させ、若年層が進学や就職を機に甲奴を離れる社会減が継続しており、⾼齢化による⾃然減と相まって⼈⼝減を加速させ、それが新
たな空き家の発⽣や地区の産業衰退につながる、という悪循環に陥っている。このことから、⼤正時代から存在する⼩川モータースは⾃動⾞販売・修理・ガソリンスタンドの事業を⾏う中で、お客様でもある地域の人々との対話からお困りごとを拾い上げ、「地域資源」を磨き直し多様な事業を⽣み出すことで、地域の担い⼿となる雇⽤を⽣み出す事業づくりを⾏なっている。`,
  video: 'https://www.youtube.com/embed/jYMjWyPJ9Qg?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
