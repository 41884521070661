import { User, Org } from './';

type Json = {
  id: number;
  text: string;
  organization: Org['json'];
  postable_user: User['json'];
  postable_organization: Org['json'];
  created_at: string;
  deletable: boolean;
  images: string[];
};

export default class Review {
  constructor(public json?: Json) {}

  id = this.json?.id;
  text = this.json?.text;
  org = this.json?.organization && new Org(this.json.organization);
  postableUser = this.json?.postable_user && new User(this.json.postable_user);
  postableOrg = this.json?.postable_organization && new Org(this.json.postable_organization);
  createdAt = new Date(this.json?.created_at!);
  deletable = this.json?.deletable;
  images = this.json?.images || [];

  postableName() {
    if (this.postableUser) return this.postableUser.name;

    return `${this.postableOrg?.category ?? ''} ${this.postableOrg?.name ?? ''}`;
  }

  postableAvatar() {
    if (this.postableUser) return this.postableUser.avatar;

    return this.postableOrg?.logo;
  }

  displayCreatedAt() {
    const diff = new Date().getTime() - this.createdAt.getTime();
    const elapsed = new Date(diff);

    if (elapsed.getUTCFullYear() - 1970) return elapsed.getUTCFullYear() - 1970 + '年前';
    if (elapsed.getUTCMonth()) return elapsed.getUTCMonth() + 'ヶ月前';
    if (elapsed.getUTCDate() - 1) return elapsed.getUTCDate() - 1 + '日前';
    if (elapsed.getUTCHours()) return elapsed.getUTCHours() + '時間前';
    if (elapsed.getUTCMinutes()) return elapsed.getUTCMinutes() + '分前';

    return 'たった今';
  }
}
