import { OrgT } from '~/state/org';

import { Review, Link, Presentation } from './';

export default class Org {
  type = 'org' as const;

  constructor(public json?: Partial<OrgT>) {}

  id = this.json?.id;
  uid = this.json?.uid;
  email = this.json?.email;
  name = this.json?.name;
  category = this.json?.category;
  logo = this.json?.logo;
  area = this.json?.area;
  cover = this.json?.cover;
  tags = this.json?.tags || [];
  sdgs = this.json?.sdgs;
  reviews = (this.json?.reviews || []).map((review) => new Review(review as any));
  vision = this.json?.vision;
  mission = this.json?.mission;
  links = (this.json?.links || []).map((link) => new Link(link));
  socialIssue = this.json?.social_issue;
  activityImage = this.json?.activity_image;
  activity = this.json?.activity || '';
  performance = this.json?.performance || '';
  owner = this.json?.owner;
  ownerTitle = this.json?.owner_title;
  ownerImage = this.json?.owner_image;
  since = this.json?.since;
  postalCode = this.json?.postal_code;
  prefecture = this.json?.prefecture;
  address = this.json?.address;
  budget = this.json?.budget || '';
  fullTimeStaff = this.json?.full_time_staff;
  partTimeStaff = this.json?.part_time_staff;
  volunteer = this.json?.volunteer;
  client = this.json?.client || '';
  reports = this.json?.reports || [];
  presentations = (this.json?.presentations || []).map((p) => new Presentation(p));
  files = this.json?.files || [];
  socialIssues = this.json?.social_issues;

  displayPostalCode() {
    const { postalCode } = this;

    if (!postalCode) return '';
    if (postalCode.length !== 7) return postalCode;
    return postalCode.slice(0, 3) + '-' + postalCode.slice(3, 7);
  }

  image() {
    return this.logo;
  }
}

export type IOrg = Omit<Org, 'type' | 'json'>;
