export const CorpFurusatoData = {
  id: 1,
  cover: '/hiroshima/cover@2x.png',
  logo: '/hiroshima/logo.svg',
  municipality: '広島県',
  area: '中山間地域',
  name: 'ひろしま版里山エコシステム',
  description:
    '中山間地域（里山・里海）の課題解決に取り組む地域団体の活動の質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業や応援者とのマッチングを図り、企業版ふるさと納税などを通じて、自治体、地域貢献企業、応援者がその活動を継続的に支えることで共存共栄していく仕組みです。',
  plan: '地域再生計画 ひろしま版里山エコシステム構築事業',
  plan_url: 'https://www.chisou.go.jp/tiiki/tiikisaisei/dai59nintei/plan/a485.pdf',
  admins: [
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
  ],
  members: [
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
    { id: 1, name: 'sample' },
  ],
  corp_furusato_relatings: [
    { name: '2021年度支援対象団体', organizations: [] },
    { name: '2021年度　企業版ふるさと納税 - 納税企業', corporates: [] },
  ],
};

export type CorpFurusatoData = typeof CorpFurusatoData;
