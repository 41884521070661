import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'okuroumori',
  id: 12,
  total_amount: 2000000,
  title: '贈ろう森',
  issues: ['環境', '教育', '森林保全', '地域資源', '災害対応'],
  fund_uses: [
    {
      id: 1,
      title: '贈ろう森を次年度以降パッケージキットとして販売',
      description: `贈ろう森を次年度以降パッケージキットとして販売をする予定。その資材代、原材料費等`,
      image: '/okuroumori/use1.jpg',
    },
    {
      id: 1,
      title: 'イベント',
      description: `イベントを行う際、環境に対しての学び、体験価値などクォリティの担保が信用度、参加意欲を高める。そのため専門家の派遣やスタッフの充実を図るための人件費、謝金等`,
      image: '/okuroumori/use2.jpg',
    },
    {
      id: 1,
      title: '広告費等',
      description: `贈ろう森のコンセプトを伝えるための分かりやすい広報物や広告戦略必要な広報費等（その他諸経費）`,
      image: '/okuroumori/use3.jpg',
    },
  ],
  purpose_title: '日常生活のなかで木とともに過ごすことで森を身近に感じ、そして育てた木を森に送る',
  purpose_text: `近年人が森に入らなくなる生活が一般化し、その影で山道は荒れ、草木は無造作に生い茂りその結果さらにまた人が寄り付かなくなるという悪循環になっています。贈ろう森は日常生活のなかで木とともに過ごすことで森を身近に感じ、そして育てた木を森に送ることで里山とのつながりもつくる、人と森との関係づくりを目的としています。中長期的には、全国の各中山間地域で関連するテーマのイベントを継続的に実施し、それぞれの地域特徴とストーリーを商品化予定。`,
  purpose_image: '/okuroumori/purpose.jpg',
  project_title: '苗木を一定期間自宅で育て、必要とされている地域に贈り植林するプログラムの構築',
  project_text: `以下の3つのフローで里山に自生している苗木を都会に住む家庭や企業に育ててもらい、一定期間経過後また木を必要としているところへ『贈る』環境循環型のプロジェクトです。
① 「贈ろう森」というキットの作成:
材料は里山で自生している苗木、その地から採取した土、ポットとしてその地で間伐された木を使用し育てるコトができるよう肥料等を1つのセットとしています。このキットをイベントでのワークショップなどで作成し土や木に触れながら自然環境のことを学びます。
②贈ろう森を自宅で育てる:
 作成した贈ろう森は自宅やオフィスなど日常の環境下で参加者や購入者に育ててもらいます。その間毎日の水やりなどで常に森とふれる機会を持つことができます。
③贈ろう森を木を必要としているところへ贈る:
 一定期間育てた苗木はまた木を必要としているところへ贈り植樹します。贈り先は例えば緑化を必要としている山肌があらわになった場所。防風林を必要としているところ、公園、高速道路ののり面、土砂災害被災地など様々な場所が想定されます。`,
  project_image: '/okuroumori/project.jpg',
  about: `贈ろう森は里山に自生している苗木を都会に住む家庭や企業に育ててもらい、一定期間経過後また木を必要としているところへ『贈る』環境循環型のプロジェクトです。近年人が森に入らなくなる生活が一般化し、その影で山道は荒れ、草木は無造作に生い茂りその結果さらにまた人が寄り付かなくなるという悪循環になっています。贈ろう森は日常生活のなかで木とともに過ごすことで森を身近に感じ、そして育てた木を森に送ることで里山とのつながりもつくる、人と森との関係づくりを目的としています。`,
  video: null,

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
