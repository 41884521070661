import { Competition } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

export default class {
  static list(isIndex) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('competitions'), { is_index: isIndex })
        .then((obj) => {
          resolve(obj.competitions.map((comp) => new Competition(comp)));
        })
        .catch(reject);
    });
  }

  /** @returns {Promise<Competition>} */
  static get(id) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`competitions/${id}`))
        .then((obj) => {
          resolve(new Competition(obj.competition));
        })
        .catch(reject);
    });
  }

  static takemura2021() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('competitions/takemura2021'))
        .then((obj) => {
          resolve(obj.competitions.map((comp) => new Competition(comp)));
        })
        .catch(reject);
    });
  }
}
