import React, { useState } from 'react';
import styled from 'styled-components';

import { SusnetImage } from '~/components/_wrapper/SusnetImage';
import { useAsyncEffect } from '~/hooks/useAsyncEffect';
import { sleep } from '~/utils/sleep';

import IconLoadingSpinner from '@public/icons/loading-spinner.svg';

export type LoadingProps = {
  show?: boolean;
  noDelay?: boolean;
  delayTime?: number;
  zIndex?: number;
  onlySpinner?: boolean;
  spinnerWidth?: string;
  spinnerHeight?: string;
};

export function Loading({
  show,
  noDelay,
  delayTime,
  zIndex,
  onlySpinner,
  spinnerWidth = '150px',
  spinnerHeight = '150px',
}: LoadingProps) {
  const [isActive, setIsActive] = useState(noDelay ?? false);

  // NOTE: チラつきを抑えるために、少し遅れてloadingを表示する
  useAsyncEffect(async () => {
    if (noDelay) return;
    await sleep(delayTime ?? 500);
    setIsActive(true);
  }, []);

  if (show === false || !isActive) return null;

  if (onlySpinner) {
    return <IconLoadingSpinner width={spinnerWidth} height={spinnerHeight} />;
  }

  return (
    <StyledWrapper style={{ zIndex }}>
      <StyledBackground />
      <IconLoadingSpinner width={spinnerWidth} height={spinnerHeight} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  opacity: 0.8;
  top: 0;
  left: 0;
  background-color: white;
  z-index: -1;
`;
