import { Room, User, Org, Org2 } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

import { ORGS } from './Org';

export default class {
  static list() {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL('rooms'))
        .then((obj) => {
          resolve(obj.rooms.map((room) => this.setOrg(new Room(room))));
        })
        .catch(reject);
    });
  }

  static get(id) {
    return new Promise((resolve, reject) => {
      request
        .GET(apiURL(`rooms/${id}`))
        .then((obj) => {
          resolve(this.setOrg(new Room(obj.room)));
        })
        .catch(reject);
    });
  }

  static create(holdable) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const holdableType = (() => {
        switch (holdable.constructor) {
          case User:
            return 'User';
          case Org:
            return 'Organization';
          case Org2:
            return 'Organization';
          default:
            return null;
        }
      })();

      formData.append('room_member[holdable_type]', holdableType);
      formData.append('room_member[holdable_id]', holdable.id);

      request
        .POST(apiURL('rooms'), formData)
        .then((obj) => {
          resolve(new Room(obj.room));
        })
        .catch(reject);
    });
  }

  static read(id) {
    return new Promise((resolve, reject) => {
      request
        .POST(apiURL(`rooms/${id}/read`))
        .then(resolve)
        .catch(reject);
    });
  }

  static setOrg(room) {
    room.roomMembers.map((rm) => {
      if (rm.holdableOrg) {
        ORGS.map((o) => {
          if (rm.holdableOrg.uid === o.uid) {
            rm.holdableOrg = Object.assign(new Org2(), o, {
              id: rm.holdableOrg.id,
            });
          }
        });
      }
    });

    return room;
  }
}
