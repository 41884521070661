import type * as Models from '~/_legacy/models';

import { request, apiURL, authToken } from '~/utils';

import { CurrentCorporateT } from './CurrentCorporate';
import * as API from './index';

type SessionResponse = { token: string };

export type LoginT = {
  email: string;
  password: string;
};

async function createUserSession(params: LoginT): Promise<Models.User> {
  const { token } = await request.POST<SessionResponse>(apiURL('session/user'), params);

  authToken.set({ token, type: 'user' });

  // TODO: #api #current useSWRCurrentLoginに置き換えたら消す
  return await API.CurrentUser.get();
}

async function createOrgSession(params: LoginT): Promise<Models.Org> {
  const { token } = await request.POST<SessionResponse>(apiURL('session/organization'), params);

  authToken.set({ token, type: 'org' });

  // TODO: #api #current useSWRCurrentLoginに置き換えたら消す
  return await API.CurrentOrg.get();
}

async function createCorporateSession(params: LoginT): Promise<CurrentCorporateT> {
  const { token } = await request.POST<SessionResponse>(apiURL('session/corporate'), params);

  authToken.set({ token, type: 'corporate' });

  // TODO: #api #current useSWRCurrentLoginに置き換えたら消す
  return await API.CurrentCorporate.get();
}

export type CurrentSessionT =
  | Awaited<ReturnType<typeof createCorporateSession>>
  | Awaited<ReturnType<typeof createOrgSession>>
  | Awaited<ReturnType<typeof createUserSession>>;

export default class Session {
  /** 配列順にログインの処理を行う */
  static async create(params: LoginT): Promise<CurrentSessionT> {
    for (const fn of [createCorporateSession, createOrgSession, createUserSession]) {
      try {
        return await fn(params);
      } catch (e) {
        continue;
      }
    }
    throw new Error('メールアドレスかパスワードが間違っています。');
  }
}
