import { Message } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

export default class {
  static create({ text, roomId }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('message[text]', text);
      formData.append('message[room_id]', roomId);

      request
        .POST(apiURL('messages'), formData)
        .then((obj) => {
          resolve(new Message(obj.message));
        })
        .catch(reject);
    });
  }
}
