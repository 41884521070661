import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/sanken/logo.png',
  cover: '/sanken/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/sandankyo',
    },
  ],
  uid: 'sanken',
  category: '特例認定特定非営利活動法人',
  name: '三段峡－太田川流域研究会',
  area: '中国地方',
  sdgs: [4, 15, 11],
  social_impacts: [
    {
      name: '教育',
      value: 4,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 0,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 3,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 0,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 0,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 3,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 159,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 67,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 146,
    },
  ],
  vision: '都市と里山が豊かに関係しあい、地域の魅力を積み重ねる社会',
  mission: `三段峡を調べ、伝え、地域をつなぐことを使命とします。地域資源の調査、研究を基にして、地域の持つ価値を新たにしていきます。

地域を守り、誇りを持って生きるための環境教育と、都市と田舎の豊かな関係をつくる観光事業を発展させ、地域の持つ本質的な価値が増強し続けるループを構築します。

生み出された価値を多様なステークホルダーとの協働によってさらに強め、生物多様性と美しさが保たれる社会の構築の為に活動します。`,
  tags: [
    {
      id: 1,
      name: '環境',
    },
    {
      id: 1,
      name: '教育',
    },
    {
      id: 1,
      name: 'CO2排出削減',
    },
    {
      id: 1,
      name: '森林保全',
    },
    {
      id: 1,
      name: '地域資源',
    },
  ],
  tag_text:
    '私たちは海外資源の輸入による里山からの需要の喪失と、生活様式の変化に伴う価値観の変容が「持続可能ではない社会」の原因にあると考えています。しかし目先の課題解決では社会的な支持や、複雑化した課題に対して必要とされるコレクティブインパクトを及ぼせません。里山の新しい価値を生み出し、豊かさと持続可能性が共存する里山と都市の関係づくりが、多様な主体と共に解決すべき社会課題だと考えています。',
  message_title: `体験と学びの
三段峡野外博物館`,
  message_text: `私たちは「三段峡野外博物館」をテーマに，博物館の機能である「収集・保存・研究・公開」をベースにした活動をしています。また保全や調査活動を基に団体・個人と地域の自然資源を結びつけるゲートキーパーの役割を担っています。かつて観光地として栄え，文化財保護法・自然公園法で保護され，景観や生物多様性が保証された希少なフィールドである「特別名勝三段峡」をモデルに，環境保全と歴史文化の伝承・教育人材育成に取り組んでいます。三段峡は、極相林でありながら多様な生態系と本来の河川環境が維持された希少な場所です。反面、ダムによる電源開発など人と自然の狭間に苦しむ歴史や、広島県でワーストワンの人口減少率・高齢化率をもつ過疎の最も厳しい町でもあります。この日本のもつ「可能性」と「危機」の双方が顕著に表れた地域を、豊かで持続可能な社会へのモデルケースにするための活動に取り組んでいます。

安芸太田町内の全小学校をはじめ，町内外の中高生への環境教育プログラムの提供や環境省SDGs研修を実施しています。行政機関・一般企業などさまざまな団体と連携して自然環境や歴史を学ぶツアーを開催し，物見遊山の観光から「体験・学びの場」として新たな価値を加え，地域資源を子どもたちの誇りにつなげています。
また，地域住民の話し合いをサポートする勉強会にも取り組み，地域に出かけて話し合いを促進するファシリテーターを担っています。
当団体は行政などからの公的な収入はなく、会費と寄付金を主な財源にしています。環境団体としては寄付金の額は県内で１番目の規模です。`,
  activities: [
    {
      id: 1,
      title: '「調べる」事業',
      text: '三段峡の自然や歴史を調査・研究しており，自然分野は専門家を招聘して鳥類・木本類・両生類などの調査をし、深入山では希少チョウ類の研究と保全活動を行っています。地域の自然の専門家と自然への関心の高い子供たちが一緒に研究・保全活動をする場をつくり、環境保全と将来の地域の環境を守るリーダーを育てています。',
      image: '/sanken/activity1.jpg',
    },
    {
      id: 1,
      title: '「伝える」事業',
      text: `「子ども同士が学び合う」をコンセプトに、中学生が講師となりプログラムをつくり、小学生に教える「さんけん自然塾」を運営しています。広島大学中坪ゼミや広島大学総合博物館学生スタッフと協力し、地域の自然を伝える手作りビジターセンターを作っています。
町内外の小中学校及び高校生に対して授業を実施し、市内の大学や子ども会などと協力して太田川流域に住む子ども達への教育活動を行っている。また，町内のガイド組織等に対して研修事業を実施し、伝える側の育成にも取り組んでいます。`,
      image: '/sanken/activity2.jpg',
    },
    {
      id: 1,
      title: '「つなぐ」事業',
      text: `町外と町内の団体を結びつけるなど地域活性化に取り組んでいます。地域内の仲間つくりを促す「あきおおたの楽しい100人」、町の長期総合計画を学びよりよい町づくりを促す「あきおおた長期総合計画をよむ会」、話し合いの進行役を育てる「ファシリテーション勉強会」を運営しています。
地域の自然資源の価値を高め、町内外の事業者と新しい里山の魅力を作り出しています。`,
      image: '/sanken/activity3.jpg',
    },
  ],
  owner_title: '理事長',
  owner: '本宮 炎',
  owner_image: '/sanken/owner.png',
  since: '2016/11/07',
  postal_code: '7311381',
  prefecture: '広島県',
  address: '山県郡安芸太田町柴木1734',
  budget: '500万円~1,000万円',
  full_time_staff: '~10',
  part_time_staff: '~10',
  volunteer: '50~100',
  client: `広島県
広島市
安芸太田町

ゆうちょ銀行`,
  performance: `●教育機関等への授業時間は年間40時間となり，町外の団体との共同事業は累積36事業を達成した。
●三段峡を中心とした太田川上流部自然や文化，豊かさを共有する「エコツアー」を（株）良品計画無印良品広島ブロックと共同で開始し、昨年は88名から好評を得た。また同社と町内の休耕田の活用や、空き家活用事業を行い地域活性化に貢献している。
●地域住民を対象とした勉強会では，従来から継続していたファシリテーション活動の幅を広げ、地域で主役になって積極的に活動している方の発表の場として「あきおおたの楽しい100人」を継続的に主催し、合計266人が参加した。
●町の施策と住民の想いをつなぐ「あきおおたの長期総合計画を読み解く会」を開催し、令和元年に策定された安芸太田町長期総合計画後期基本計画には同会の意見が多数採用されるなど、アドボガシーにも取り組んでいる。
●フィールド調査については，専門家からフィールドを学ぶため「歩く会」を開催し，両生類や鳥，植物など，テーマを変えて実施。
●深入山では絶滅危惧のチョウ類の調査を継続し，個体数の増加が確認されるなど，保護エリアの設定など環境保全を行った。
●平成30年には安芸太田町より依頼され、三段峡で初めてとなるガイドブックへの執筆や企画を行った。
●環境保全への継続寄付者は125人となった。予算規模は約800万円のうち6割（約470万円）は会費と寄付金で占めており，うち９割（約425万円）が寄付金となっている。
●令和2年度から、東京海上日動とのパートナーシップにより「さんけん自然塾」を開設した。「子供同士が自然のフィールドで学び合う」をコンセプトに地域の環境を守るリーダーの育成に取り組んでいる。
●組織運営についての取り組みでは、広島県で最初の非営利組織評価センターの「グッドガバナンス認証」や設立から最短での「特例認定制度」の認定を受けるなどしている。

●令和元年度には，広島県主催の「ひろしま里山グッドアワード」にエントリーし，地域資源の活用・新たな価値の位置づけ・連携の多様性などが評価され，大賞を受賞した。
●地元の環境団体におくられるRCC/ラブグリーン賞を令和2年に受賞した。
※ひろしま里山グッドアワードは，中山間地域ならではの資源を活用して新しい価値やサービスの提供につながる優れた活動を行う個人・団体`,
  locations: '/sanken/location.png',
  video: 'https://www.youtube.com/embed/K7iiWH6fGkc?rel=0',
  video_thumbnail: 'https://img.youtube.com/vi/K7iiWH6fGkc/0.jpg',
  documents: [
    {
      name: 'さんけん2020年度事業報告書',
      url: 'https://drive.google.com/file/d/1QYzzlCQCjp6mF3viSJOkWWyTgmK5bVrk/preview',
    },
    {
      name: 'さんけん_年次報告書2019',
      url: 'https://drive.google.com/file/d/1R2eFuNv6UKO-Ez22vWzaNySWk9wsLqwU/preview',
    },
    {
      name: 'さんけん_年次報告書2018',
      url: 'https://drive.google.com/file/d/1QboUgdnoLns-Bow7lOzO8f1U2uYHNqpg/preview',
    },
  ],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/sanken/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
