import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'hoshihara',
  id: 11,
  total_amount: 2000000,
  title: 'ふるさと体験ガイドセンター構築事業',
  issues: ['教育', '環境', '子育て支援', '耕作放棄地'],
  fund_uses: [
    {
      id: 1,
      title: '『ふるさと体験ガイドセンター』の整備',
      description: `体験施設に隣接する耕作放棄地と山林を、体験エリアとして整備する。
＜整備内容＞
・耕作放棄地や山林の観察道（木道）整備
・木道の終点に屋外デッキを整備`,
      image: '/hoshihara/use1.jpg',
    },
  ],
  purpose_title: '中山間地域への関心を広げ、ふるさとに愛着を持つ人を増やす',
  purpose_text: `「ふるさと体験ガイドセンター（仮）」機能の構築を通じて「ふるさと」体験の機会を増やし、中山間地域への関心を広げ、ふるさとに愛着を持つ人を増やす。中山間地域の様々な課題を解決していく手段の一つとしての、持続可能な社会づくりに貢献する。`,
  purpose_image: '/hoshihara/purpose.jpg',
  project_title: 'いつでもふるさと体験ができる環境を整える',
  project_text: `「ふるさと体験ガイドセンター（仮）」機能を構築していくビジョンを描き、今後３年間で実現化を目指す。
①「ふるさと体験ガイドセンター」を定期的（週３～４日）オープンできる仕組みを構築する。
②施設隣の田と里山（耕作放棄地や放置山林）を、里山づくりサポーター等の支援を得ながら、あそんでまなぶ「里山生きもの探検エリア」として整備をすすめ、センター開館日に開放する。
③イベント開催時以外でも、ガイドセンターで下記をはじめとしたふるさと体験ができる環境を整える。
- 市農村交流による地域づくり
- 体験フィールドづくり
- 地域資源の掘り起こしと調査保全・活性化
- 人材育成・ネットワークづくり`,
  project_image: '/hoshihara/project.png',
  about: `この組織は「ふるさとを100年後の未来につなぐ」自然豊かで昔からの暮らしが今なお残る「ふるさと」の教育力・癒しの力・居場所としての機能・自然と人との共生力・農的な暮らしの知恵や農村景観などを活かしたさまざまな活動を通して，これからのふるさとづくりと未来を担う人づくりを推進することをもって，持続可能な地域社会づくりに寄与することを目的としています。`,
  video: null,

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
