import { safeWindow } from './for-next/safeWindow';

const localStorage = safeWindow((window) => window.localStorage);

export default class storage {
  static get(key: string): unknown {
    const maybeValue = localStorage?.getItem(key);
    if (!maybeValue) return;
    try {
      return JSON.parse(maybeValue);
    } catch {
      return maybeValue;
    }
  }

  static set(key: string, value: unknown) {
    localStorage?.setItem(key, JSON.stringify(value));
  }

  static delete(key: string) {
    localStorage?.removeItem(key);
  }
}
