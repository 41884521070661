import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/okuroumori/logo.jpg',
  cover: '/okuroumori/cover.jpg',
  links: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/okuroumori/',
    },
  ],
  uid: 'okuroumori',
  category: '一般社団法人',
  name: 'My Japan',
  area: '全国',
  sdgs: [15, 7, 4],
  social_impacts: [
    {
      name: '教育',
      value: 2,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 0,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 3,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 4,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 1,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 0,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 0,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 10,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 70,
    },
    {
      id: 1,
      name: '組織外連携社',
      value: 100,
    },
  ],
  vision:
    '人と森と動物と、里山にかかわるあらゆる者が自然かつ無理なくそれぞれの生き方を補完しあい、見守り、尊重しあう暮らしをビジョンとして掲げます。元来バランスを持って暮らしてきたはずが生活様式の変化などにより崩れてしまっている状態があります。私たちは事業を通して誰もが当たり前にこのビジョンを共有することができるよう活動を続けていきます。',
  mission: `各地域で大切に守られてきたもの、受け継がれてきたことを「紡ぎ」「伝え」「育てる」ことが私たちのミッションです。贈ろう森という事業は里山の象徴ともいえる自然や森と、人を繋ぎ良好な関係を生み出すコト。そのつながりから里山の文化や風土、歴史や伝統にも関心を持ってもらいエリアを超えた人々の交流を生み、里山に愛着を持つ関係人口を増やしていきます。`,
  tags: [
    {
      id: 1,
      name: '環境',
    },
    {
      id: 1,
      name: '森林保全',
    },
    {
      id: 1,
      name: '緑化',
    },
    {
      id: 1,
      name: '環境教育',
    },
    {
      id: 1,
      name: '地域資源',
    },
    {
      id: 1,
      name: '地域産業の衰退',
    },
    {
      id: 1,
      name: '関係人口創出',
    },
    {
      id: 1,
      name: '森林',
    },
    {
      id: 1,
      name: '里',
    },
  ],
  tag_text:
    '人と森の関係が希薄になってしまっていることにより様々な社会課題が生まれています。例えば森が荒れることにより食べ物を求めて人の居住域にイノシシなどが下りてくることなどの「獣害」。自然の変化を察知できず、弱った土壌が引き起こす土砂崩れなどの「災害」 。都心部に人口が集中してしまい里山や森の管理に携わる人が減る「人口減少」など様々な課題が生まれており、本事業でこれらの解決を目指します。',
  message_title: `人と森との関係を深くし、日常的に大切なものを守りつづける気持ちが芽生えるように`,
  message_text: `自然や森の恵みが古くから各地域の豊かさを守ってきました。近年その資源である森が人との関りが薄くなることで荒れてしまっています。この贈ろう森という事業を通じて、今一度人と森との関係を深くして日常的に大切なものを守りつづける気持ちが芽生えるコトを目指して取り組んでいます。広く贈ろう森のビジョンが皆様に伝われば幸せです。よろしくお願いします。`,
  activities: [
    {
      id: 1,
      title: '資源を「活かすコト」【木の苗を採取し森の荒廃を防ぎ資源を活用】',
      text: `・木が茂る山林に新たに生まれた木の苗を活用
・木々の間隔を保つことで山が荒れることを防ぐ
・間伐資材をキットの材料として活用`,
      image: '/okuroumori/activity1.jpg',
    },
    {
      id: 1,
      title: '森を「育てるコト」【森に触れ、知り、育てる経験で関心を高める】',
      text: `・木や森など自然環境を学ぶ機会創出
・自宅等で育てる一過性ではない森との関係作り
・子供の自然体験の場を提供
・企業の地域貢献機会、環境への意識向上`,
      image: '/okuroumori/activity2.jpg',
    },
    {
      id: 1,
      title: '活躍を「贈るコト」【育てた木は森を必要としている場所へ送る】',
      text: `・土砂災害があった場所や警戒区域
・防災＋自然環境保護へ貢献(SDGs要素）
・森林放牧など新たに木を増やすエリア
・公園など緑地化エリア など`,
      text: '',
      image: '/okuroumori/activity3.jpg',
    },
  ],
  owner_title: 'プロジェクトリーダー',
  owner: '石飛聡司',
  owner_image: '/okuroumori/owner.jpg',
  since: '2018/08/01',
  postal_code: '7314215',
  prefecture: '広島県',
  address: '安芸郡熊野町城之堀4丁目7番7号',
  budget: '1,000万円＜3,000万円',
  full_time_staff: '<10',
  part_time_staff: '<10',
  volunteer: '50<100',
  client: `ゆうちょ銀行`,
  performance: `実績①
イオンモールSDGsウィークイベント
日時　：2021年9月23日
会場　：イオンモール堺鉄砲町
時間　：11時-16時
参加数：60人
実績②
贈ろう森ワークショップ主催イベント
日時　：2021年10月25日
会場　：つぼくさ農園
時間　：13時～15時
参加数：15人
実績③
リバーDOイベント
日時　：2021年10月23・24日
会場　：広島太田川湖畔
時間　：13時-17時
参加数：30人
実績④
久保アグリファーム　植樹会＆WS
日時　：2022年5月15日
会場　：久保アグリファーム
時間　：13時-17時
参加数：30人
実績⑤
山道整備・苗採取活動
日時　：2020年10月-2021年5月
会場　：二葉山（広島市東区）
参加数：延べ400人 `,
  locations: '/okuroumori/location.png',
  video: null,
  video_thumbnail: null,
  documents: [
    {
      name: '贈ろう森参考資料',
      url: 'https://drive.google.com/file/d/1xXKYTF_XqCxYm2k5xeOOspHRSLL_0_6O/preview',
    },
  ],
  images: ['/okuroumori/image1.jpg', '/okuroumori/image2.jpg'],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/okuroumori/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
