import { useSetRecoilState } from 'recoil';

import * as API from '~/_legacy/api';

import { useAsyncEffect } from '~/hooks/useAsyncEffect';
import { CurrentUserState } from '~/state/currentUser';
import { authToken } from '~/utils';

export default function WithCurrentUser({ children }) {
  const setCurrentUser = useSetRecoilState(CurrentUserState);

  useAsyncEffect(async () => {
    const type = authToken.getType();
    if (type === 'no-login') {
      setCurrentUser(null);
      return;
    }

    try {
      switch (type) {
        case 'user': {
          setCurrentUser(await API.CurrentUser.get());
          return;
        }
        case 'org': {
          setCurrentUser(await API.CurrentOrg.get());
          return;
        }
        case 'corporate': {
          setCurrentUser(await API.CurrentCorporate.get());
          return;
        }
      }
    } catch {
      setCurrentUser(null);
    }
  }, []);

  return children;
}
