import { UrlObject } from 'url';

export function getLoginUrlWithRedirectTo(): UrlObject {
  return {
    pathname: '/login',
    query: {
      get redirect_to(): string {
        const { href, origin } = location;
        return encodeURIComponent(href.replace(origin, ''));
      },
    },
  };
}
