import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'fuudo',
  id: 10,
  total_amount: 2000000,
  title: 'えたじま未来の海づくり大作戦',
  issues: ['海洋保全', '地域資源', '環境課題'],
  fund_uses: [
    {
      id: 1,
      title: '海辺の小さな拠点の整備費 (1,200,000円)',
      description: `以下の機能を備えた海辺の小さな拠点（30㎡程度の倉庫を予定）の整備費
・海岸清掃やマリンアクティビティ等に必要な道具の保管
・人が集まれるちょっとしたスペース`,
      image: '/fuudo-2022/use1.JPG',
    },
    {
      id: 1,
      title: '里海体験イベントの企画・運営に係る経費 (800,000円)',
      description: `海の団体や行政機関との調整、HPやSNSを通じた情報発信、海岸清掃や里海体験イベントの企画運営などに係る
・人件費（求人費用含む）
・専門家への謝金
・備品、消耗品費`,
      image: '/fuudo-2022/use2.jpg',
    },
  ],
  purpose_title: '身近な海を次世代と共に作る',
  purpose_text: `私たちが暮らす広島県江田島市。広島湾に浮かぶこの島の周辺の海は美しく、瀬戸内海系の多くの生き物が生息しており、牡蠣養殖をはじめ様々な産業のよりどころとなっています。一方で、海洋ゴミの大量発生や貧栄養化による生物の減少、温暖化による生態系の変化、子どもたちの海離れ、海難事故などの社会課題も顕在化しています。

  こういった社会課題に向き合い、私たちの大好きな身近な海を、今より良い形で次世代に引き継ぐための活動基盤の強化を本プロジェクトの目的とし、これらの活動を江田島の子供たちと共に実施することにより、海を通じて人を育てる「えたじま渚の学校」の設立を目指します。`,
  purpose_image: '/fuudo-2022/purpose.jpg',
  project_title: '島の子供たちが海を知り、学び、感じるための仕組み作り',
  project_text: `①海辺の小さな拠点づくり
海岸清掃、里海体験イベント、SUP・カヌー体験スクール、ライフセービング講習など、江田島市では当社も含め、海の団体が様々な活動に取り組んでいます。
「海を守る」「海を楽しむ」「海から学ぶ」。各団体が取り組んでいるこれらの取組みの拠点となる場をつくり、地元の子どもたちをはじめ、海に関わる人を増やします。

②里海体験イベントの企画・運営
主に島の子どもたちを対象にした里海体験イベントを企画し運営します。ビーチクリーン、磯観察、藻場再生、マリンアクティビティ体験など、海で活動する団体と協働で行い、地元の子どもたちが里海を体験し学ぶ機会をつくります。`,
  project_image: '/fuudo-2022/project.jpg',
  about: `「地域の誇れる風土を次世代につなぐ」をミッションに、地域密着型のまちづくり会社として2018年11月に一般社団法人フウドは設立しました。広島湾に浮かぶ江田島・能美島（江田島市）を拠点に、コミュニティスペースの運営、移住支援・空き家の利活用、企業誘致、地域資源を活用した観光コンテンツの企画運営、そして海洋環境・海洋教育へのアクションなど、地域内外の人や企業の交流を通じて持続可能なまちづくりを目指しています。`,
  video: 'https://www.youtube.com/embed/pVlaNexSpVM?rel=0',

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
