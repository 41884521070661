import { Review } from './';

/** /api/v1/current_user */
type UserT = {
  id?: number;
  name?: string;
  email?: string;
  nickname?: string;
  private_name?: string;
  avatar?: string;
  birth?: string;
  private_birth?: string;
  gender?: string;
  area?: string;
  title?: string;
  reviews?: any[];
};

export default class User {
  type = 'user' as const;

  constructor(public json: UserT = {}) {}

  id = this.json.id;
  fullName = this.json.name;
  email = this.json.email;
  nickname = this.json.nickname;
  privateName = this.json.private_name;
  avatar = this.json.avatar;
  birth = this.json.birth;
  privateBirth = this.json.private_birth;
  gender = this.json.gender;
  area = this.json.area;
  title = this.json.title;
  reviews = (this.json.reviews || []).map((review) => new Review(review));

  get name() {
    return this.nickname || this.json.name;
  }

  displayGender() {
    switch (this.gender) {
      case 'male':
        return '男性';
      case 'female':
        return '女性';
      case 'other':
        return 'その他';
      case 'priv':
        return '非公開';
      default:
        return '';
    }
  }

  image() {
    return this.avatar;
  }
}
