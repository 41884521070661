import dayjs from 'dayjs';

import { Room, User, Org } from './';

type Json = {
  id: number;
  room: Room['json'];
  holdable_user: User['json'];
  holdable_organization: Org['json'];
  text: string;
  created_at: string;
};

export default class Message {
  constructor(public json?: Json) {}

  id = this.json?.id;
  room = new Room(this.json?.room);
  holdableUser = this.json?.holdable_user && new User(this.json.holdable_user);
  holdableOrg = this.json?.holdable_organization && new Org(this.json.holdable_organization);
  text = this.json?.text;
  _holdable: null = null;
  createdAt = new Date(this.json?.created_at ?? '');

  holdable() {
    return this._holdable || this.holdableUser || this.holdableOrg;
  }

  displayCreatedAt() {
    const diff = new Date().getTime() - this.createdAt.getTime();
    const elapsed = new Date(diff);

    if (elapsed.getUTCDate() - 1) {
      return dayjs(this.createdAt).format('YYYY/MM/DD HH:mm');
    } else {
      return dayjs(this.createdAt).format('HH:mm');
    }
  }
}
