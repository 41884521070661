import { stringify } from 'query-string';
import superagent, { SuperAgentRequest } from 'superagent';

import config from '~/config';
import authToken from '~/utils/authToken';

export type RequestOption = {
  path: string;
  params?: {};
  method: 'get' | 'post' | 'put' | 'patch' | 'del';
  isFullPath?: boolean;
  headers?: Record<string, string>;
};

export class UnauthorizedError extends Error {}

export async function requestCommon(option: RequestOption) {
  const { path, params, method, isFullPath, headers } = option;
  const fullPath = isFullPath ? path : config.api_root + path;

  let requestAgent: SuperAgentRequest;
  if (method === 'get') {
    // refs: https://github.com/sindresorhus/query-string#options-1
    const paramsText = stringify(params ?? {}, { arrayFormat: 'bracket' });
    requestAgent = superagent.get(fullPath).query(paramsText);
  } else {
    requestAgent = superagent[method](fullPath).send(params);
  }

  const { token } = authToken.get() ?? {};
  if (token) {
    requestAgent = requestAgent.set('Authorization', token);
  }

  if (headers) {
    requestAgent = requestAgent.set(headers);
  }

  // NOTE: awaitされた時(thenが呼ばれる)と内部的にはsa.end()が実行される
  // refs: https://github.com/ladjs/superagent/blob/master/src/request-base.js#L258
  const res = await requestAgent;
  return res.body;
}

type PickedOption = Pick<RequestOption, 'isFullPath'>;

/**
 * @example
 * ```ts
 * // /api/v1/postsをGETする例 QUERYはobjectで指定する
 * const { posts } = await request.GET<{ posts: object }>('/v1/posts', QUERY)
 *
 * // 外部APIを使用するとき または`apiUrl`関数を使用するとき 第3引数に`isFullPath: true`を指定する
 * const result = await request.GET('https://hoge.huga.com', BODY, { isFullPath: true });
 * ```
 */
export const request = {
  async GET<T = unknown>(path: string, query = {}, options: PickedOption = {}): Promise<T> {
    return await requestCommon({ path, params: query, method: 'get', ...options });
  },
  async POST<T = unknown>(path: string, params = {}, options: PickedOption = {}): Promise<T> {
    return await requestCommon({ path, params, method: 'post', ...options });
  },
  async PUT<T = unknown>(path: string, params = {}, options: PickedOption = {}): Promise<T> {
    return await requestCommon({ path, params, method: 'put', ...options });
  },
  async PATCH<T = unknown>(path: string, params = {}, options: PickedOption = {}): Promise<T> {
    return await requestCommon({ path, params, method: 'patch', ...options });
  },
  async DELETE<T = unknown>(path: string, params = {}, options: PickedOption = {}): Promise<T> {
    return await requestCommon({ path, params, method: 'del', ...options });
  },
};
