import Fuudo from './CorpFurusatoPresentations/fuudo';
import Fuudo2022 from './CorpFurusatoPresentations/fuudo-2022';
import Hoshihara from './CorpFurusatoPresentations/hoshihara';
import Minato0524 from './CorpFurusatoPresentations/minato0524';
import NurseAndCraft from './CorpFurusatoPresentations/nurse-and-craft';
import NurseAndCraft2022 from './CorpFurusatoPresentations/nurse-and-craft-2022';
import Ogawamotors from './CorpFurusatoPresentations/ogawamotors';
import Ogawamotors2022 from './CorpFurusatoPresentations/ogawamotors-2022';
import Okuroumori from './CorpFurusatoPresentations/okuroumori';
import Sanken from './CorpFurusatoPresentations/sanken';
import Sanken2022 from './CorpFurusatoPresentations/sanken-2022';
import YohakuBi from './CorpFurusatoPresentations/yohaku-bi';
import YohakuBi2022 from './CorpFurusatoPresentations/yohaku-bi-2022';
import OrgAPI from './Org';

const PRESENTATIONS = [NurseAndCraft, Sanken, YohakuBi, Ogawamotors, Fuudo];
const PRESENTATIONS2022 = [
  NurseAndCraft2022,
  Sanken2022,
  YohakuBi2022,
  Ogawamotors2022,
  Fuudo2022,
  Hoshihara,
  Okuroumori,
  Minato0524,
];
const ALL = [...PRESENTATIONS, ...PRESENTATIONS2022];

export default class {
  static get(id) {
    return new Promise((resolve, reject) => {
      const presentation = ALL.find((p) => p.id == id);

      OrgAPI.get(presentation.orgUid, true).then((o) => {
        presentation.organization = o;

        resolve(presentation);
      });
    });
  }

  /** @returns {Promise<import("~/_legacy/models").CorpFurusatoPresentation[]>} */
  static list(id) {
    return new Promise((resolve, reject) => {
      const presentations = id == 2 ? PRESENTATIONS2022 : PRESENTATIONS;

      Promise.all(presentations.map((p) => OrgAPI.get(p.orgUid, true))).then((os) => {
        os.map((o, i) => {
          presentations[i].organization = o;
        });

        resolve(presentations);
      });
    });
  }
}
