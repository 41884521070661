export default function range(end: number): number[];
export default function range(start: number, stop: number): number[];
export default function range(startOrEnd: number, stop?: number): number[] {
  let start: number;
  if (stop == null) {
    start = 0;
    stop = startOrEnd;
  } else {
    start = startOrEnd;
  }

  const length = Math.max(Math.ceil(stop - start), 0);
  return Array.from({ length }, (_, i) => start + i);
}
