import { request, apiURL } from '~/utils';

export default class {
  static areas() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/areas'))
        .then((obj) => {
          resolve(obj.areas);
        })
        .catch(reject);
    });
  }

  static prefectures() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/prefectures'))
        .then((obj) => {
          resolve(obj.prefectures);
        })
        .catch(reject);
    });
  }

  static categories() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/categories'))
        .then((obj) => {
          resolve(obj.categories);
        })
        .catch(reject);
    });
  }

  static budgets() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/budgets'))
        .then((obj) => {
          resolve(obj.budgets);
        })
        .catch(reject);
    });
  }

  static staffs() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/staffs'))
        .then((obj) => {
          resolve(obj.staffs);
        })
        .catch(reject);
    });
  }

  static volunteers() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('config/volunteers'))
        .then((obj) => {
          resolve(obj.volunteers);
        })
        .catch(reject);
    });
  }
}
