import { type BrowserOptions } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;

export const SENTRY_COMMON_OPTION = {
  dsn:
    SENTRY_DSN ||
    'https://eec3630c5ad84c2093c6dd2c4f17aebc@o4504961670184960.ingest.sentry.io/4504961959854080',
  tracesSampleRate: 1.0,
  release: SENTRY_RELEASE,
} satisfies BrowserOptions;
