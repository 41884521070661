import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { PropsWithChildren, useEffect, useState } from 'react';

import { Loading } from '~/_legacy/components/molecules/loading';

import { AppContexts } from '~/components/_for-next/AppContexts';
import { WithCurrentUser, WithRooms } from '~/layouts';
import { Ga } from '~/utils';

import '~/css/common.css';
import '~/css/font.css';
import '~/css/legacy.scss';
import '~/css/tailwind.css';

export default function App({ Component, pageProps }: AppProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const start = () => setIsLoading(true);
    const end = () => setIsLoading(false);
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
  }, []);

  return (
    <>
      <Head>
        <link rel='icon' href='/favicon.ico' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        {/* NOTE: デバイスの横幅が375px未満の時、375pxとして画面に表示するようにする */}
        {/* load時のみ動くので、pcで検証するときはdevtoolで375px未満にした後reloadする必要あり */}
        <meta name='viewport-extra' content='width=device-width,initial-scale=1,min-width=375' />
      </Head>

      <InitTypekitScript />
      <Script
        src='https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v8.0'
        nonce='a2SFU6iH'
      />
      <Script src='https://platform.twitter.com/widgets.js' />
      <Script
        src='https://cdn.jsdelivr.net/npm/viewport-extra@2.1.4/dist/iife/viewport-extra.min.js'
        async
      />

      <Contexts>
        <Component {...pageProps} />
        {isLoading && <Loading delayTime={1500} />}
      </Contexts>
    </>
  );
}

function Contexts({ children }: PropsWithChildren) {
  return (
    <AppContexts>
      <Ga>
        <WithCurrentUser>
          <WithRooms>{children}</WithRooms>
        </WithCurrentUser>
      </Ga>
    </AppContexts>
  );
}

function InitTypekitScript() {
  return (
    <Script id='typekit-script' strategy='afterInteractive'>{`
    (function (d) {
      var config = {
        kitId: 'lgu7pto',
        scriptTimeout: 3000,
        async: true,
      },
        h = d.documentElement,
        t = setTimeout(function () {
          h.className = h.className.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
        }, config.scriptTimeout),
        tk = d.createElement('script'),
        f = false,
        s = d.getElementsByTagName('script')[0],
        a;
      h.className += ' wf-loading';
      tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
      tk.async = true;
      tk.onload = tk.onreadystatechange = function () {
        a = this.readyState;
        if (f || (a && a != 'complete' && a != 'loaded')) return;
        f = true;
        clearTimeout(t);
        try {
          Typekit.load(config);
        } catch (e) {}
      };
      s.parentNode.insertBefore(tk, s);
    })(document);
  `}</Script>
  );
}
