import type * as Models from '~/_legacy/models';

import { Org as OrgAPI } from './';

type OrgModelT = Models.Org | Models.Org2;

export default class VoiceAPI {
  static async list() {
    const orgs: Array<OrgModelT> = await OrgAPI.list();

    const voices = [...VOICES] as VoiceData[];
    voices.forEach((v) => {
      v.votingOrgs = v.votings.map((vt) => orgs.find((o) => o.name === vt) || orgs[0]);
    });

    return VOICES;
  }
}

export type VoiceData = {
  user: {
    name: string;
    title: string;
  };
  text: string;
  votings: string[];
  votingOrgs: OrgModelT[];
};

export const VOICES: Array<Omit<VoiceData, 'votingOrgs'>> = [
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　総務部',
    },
    text: 'いろいろな活動を知ることで、今後少しでも協力できることがあれば行っていきたいと思いました。',
    votings: [
      '森づくりフォーラム',
      'アンビシャス（沖縄県難病相談支援センター）',
      'Matsudo子どもの未来へwith us',
      '東海つばめ学習会',
      'ひとり親ＩＣＴ就業支援センター',
      'tadaima!',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　設計積算課',
    },
    text: '社会課題について知っていても、具体的に誰がどのようにその問題に向き合っているのかまでは知らずにいました。2回のコンペを通じてその課題の多くは、日本社会全体で解決に向け取り組むべきものだと感じました。寄付をすることは素晴らしいことですが、それに満足せず、お金では解決できない問題にも向き合わなければいけないと思いました。',
    votings: [
      '森づくりフォーラム',
      'エンカレッジ',
      'リネーブル・若者セーフティネット',
      '東海つばめ学習会',
      'ひとり親ＩＣＴ就業支援センター',
      '輝くママ支援ネットワークぱらママ',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　総務部',
    },
    text: '個人では発見できない、意欲的な活動団体が多くあることは理解していましたが、ICHIさんがいらっしゃることで新しく知る団体にも安心して寄付できます。',
    votings: [
      '森づくりフォーラム',
      'NPO法人La-Vida',
      'サンカクシャ',
      '東海つばめ学習会',
      'ひとり親ＩＣＴ就業支援センター',
      '輝くママ支援ネットワークぱらママ',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　営業',
    },
    text: '色々な社会貢献の仕方がある事を知りました｡',
    votings: [
      '自然環境復元協会（NAREC）',
      'エンカレッジ',
      'Matsudo子どもの未来へwith us',
      '東海つばめ学習会',
      'ひとり親ＩＣＴ就業支援センター',
      'WELgee',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　リノベーション部',
    },
    text: '困っている人の役に立つことを、積極的に行動しているところが自分もそうでありたいと思いました。',
    votings: [
      'にじのはしファンド',
      'Matsudo子どもの未来へwith us',
      '特定非営利活動法人山王学舎',
      '子ども家族生活サポートセンターいとでんわ',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　リノベーション部',
    },
    text: '考える時間が出来て、自分の為になっていると思われます。',
    votings: [
      'アンビシャス（沖縄県難病相談支援センター）',
      'Matsudo子どもの未来へwith us',
      'パラダイム',
      'a little',
      '輝くママ支援ネットワークぱらママ',
    ],
  },
  {
    user: {
      name: '匿名',
      title: '竹村コーポレーション　工事部',
    },
    text: 'わたしもコンペにあるようや活動に参加してみたいと思いました。とても毎回楽しみです。世の中をより良くしていこうという団体に投票していきたいです。',
    votings: [
      '森づくりフォーラム',
      'アンビシャス（沖縄県難病相談支援センター）',
      'Matsudo子どもの未来へwith us',
      '子ども未来',
      '子ども家族生活サポートセンターいとでんわ',
    ],
  },
];
