import { usePathname } from 'next/navigation';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import * as API from '~/_legacy/api';

import { RoomsState } from '~/state/room';
import { authToken } from '~/utils';

export default function WithRooms({ children }) {
  const setRooms = useSetRecoilState(RoomsState);
  const pathname = usePathname();

  React.useEffect(() => {
    const type = authToken.getType();
    if (type === 'user' || type === 'org') {
      API.Room.list().then(setRooms);
    }
  }, [pathname, setRooms]);

  return children;
}
