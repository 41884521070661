import { CorporateT } from '~/state/corporate';

import { SocialIssue, Competition, Org } from './';

export default class Corporate {
  constructor(public json?: CorporateT & Record<string, unknown>) {}

  id = this.json?.id;
  uid = this.json?.uid;
  categoryPostfix = this.json?.category_postfix;
  category = this.json?.category;
  name = this.json?.name;
  logo = this.json?.logo;
  cover = this.json?.cover;
  locations = this.json?.locations;
  title = this.json?.title;
  description = this.json?.description;
  contentImage = this.json?.content_image;
  materiality = this.json?.materiality;
  sdgs = this.json?.sdgs;
  socialIssues = (this.json?.social_issues || []).map((si) => new SocialIssue(si));
  owner = this.json?.owner;
  since = this.json?.since;
  postalCode = this.json?.postal_code;
  prefecture = this.json?.prefecture;
  address = this.json?.address;
  capital = this.json?.capital || '';
  staff = this.json?.staff || '';
  homepage = this.json?.homepage || '';
  competitions = ((this.json?.competitions as any[]) || []).map((c) => new Competition(c));
  orgs = ((this.json?.organizations || []) as any[]).map((o) => new Org(o));
  imageCaption = this.json?.image_caption || '';

  nameWithCategory() {
    if (this.categoryPostfix) {
      return `${this.name}${this.category}`;
    } else {
      return `${this.category}${this.name}`;
    }
  }
}
