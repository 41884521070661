type Json = {
  id: number;
  name: string;
  url: string;
};

export default class Link {
  constructor(public json: Json) {}

  id = this.json.id;
  name = this.json.name;
  url = this.json.url;

  isFacebook() {
    return this.name === 'Facebook';
  }

  isTwitter() {
    return this.name === 'Twitter';
  }
}
