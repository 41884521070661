import { Presentation } from '~/_legacy/models';

import { request, apiURL } from '~/utils';

export default class {
  static allIndex(isTop) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`presentations/all_index`), { is_top: isTop })
        .then((obj) => {
          resolve(obj.presentations.map((p) => new Presentation(p)));
        })
        .catch(reject);
    });
  }
  /** @returns {Promise<Presentation[]>} */
  static list(id) {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL(`competitions/${id}/presentations`))
        .then((obj) => {
          resolve(obj.presentations.map((p) => new Presentation(p)));
        })
        .catch(reject);
    });
  }

  /** @returns {Promise<Presentation>} */
  static get(id, { competitionId } = {}) {
    return new Promise((resolve, reject) => {
      request

        .GET(
          apiURL(`presentations/${id}${competitionId ? `?competition_id=${competitionId}` : ''}`),
        )
        .then((obj) => {
          resolve(new Presentation(obj.presentation));
        })
        .catch(reject);
    });
  }

  // static vote({ competitionId, presentationId }) {
  //   return new Promise((resolve, reject) => {
  //     request
  //       .POST(apiURL(`competitions/${competitionId}/presentations/${presentationId}/vote`))
  //       .then((obj) => {
  //         resolve();
  //       })
  //       .catch(reject);
  //   });
  // }

  // static unvote({ competitionId, presentationId }) {
  //   return new Promise((resolve, reject) => {
  //     request
  //       .DELETE(apiURL(`competitions/${competitionId}/presentations/${presentationId}/unvote`))
  //       .then((obj) => {
  //         resolve();
  //       })
  //       .catch(reject);
  //   });
  // }
}
