import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'nurseandcraft',
  id: 6,
  total_amount: 2000000,
  title: 'とびしま海道で創る、人生100年時代の新しい暮らし方',
  issues: ['集落の小規模化・高齢化', '老々介護', '地域の医療格差', '希薄する地域コミュニティ'],
  fund_uses: [
    {
      id: 1,
      title: 'IoTを活用したヘルスケアサービスの実証実験及びサービス開発費用 (2,000,000円)',
      description:
        '様々な資源が足りない島しょ部において、テクノロジーの導入は欠かせません。私たちは過疎地や離島の実証フィールドを有し、誰もが住み慣れた場所で医療や介護に対する不安がなくウェルビーイングな暮らしを実現できるよう、製品・技術・ソリューションの実用化に向けて検証を行っています。資金は、研究や実験で使用する備品、人件費等に使います。',
      image: '/nurse-and-craft/use1.jpg',
    },
  ],
  purpose_title: '「100年生きたら、おもしろかった」と誰もが言える世界の実現',
  purpose_text: `施設から在宅へと日本の介護政策が進む中、
①「機能不全に陥る社会保障制度」（ 叶わない自宅死、医療費の増大、医療介護人材不足など ） と
②「潜在看護師（介護士）の増加」（医療事故への不安・責任の重さ、変則的で不規則な勤務体制、出産・育児によるブランクなど ）　
という二つの問題は深まるばかりで、解決への道筋は依然として見通せない状況です。このままでは、日本中に介護難民や介護離職はあふれ、誰もが老後に不安を抱えながら生きることになります。それは健全で持続可能な社会とは言えず、私たち一人ひとりには未来に対して責任があります。私たちの目的は、誰もが人生の最期に「自分の人生は本当におもしろかった」と言える社会をつくることです。`,
  purpose_image: '/nurse-and-craft-2022/purpose.jpg',
  project_title: '善き隣人が“デジタル社会におけるウェルビーイングな暮らし”をつくる',
  project_text: `※私たちのコンセプトは住民にとって“善き隣人”であることです。

  ①地域医療の確保と顔の見えるコミュニティづくり
  住み慣れた場所で可能な限り最期まで暮らしていくための医療を確保し、希薄化したコミュニティを住民と善き隣人或いは住民同士のヘルスケアコミュニケーションによって再構築する。

  ②住民と善き隣人を支えるテクノロジー開発
  IoT活用による疾病の重篤化予防や遠隔診療（DtoPwithN）などを外部の事業者とラストワンマイルを持つ私たちが共に開発し、テクノロジーだけで終わらない人の介在するサービスとして社会に実装する。

  ③医療介護の持続可能な働き方
  医療介護のストレスフルな働き方から解放するため、パラレルワークを導入して精神をリバランスする。好きな仕事を続けられるように、二つの仕事を持つことで精神の安定を図り、持続可能な働き方として人を支える側も健康でいられる環境を整える。`,
  project_image: '/nurse-and-craft-2022/project.jpg',
  about: `とびしま海道周辺島々の過疎地では、人口減少によって医療・介護保険制度だけに依存したビジネスは経営的に成立せず、その結果、医療や介護サービスにおいて地域格差が生じています。このような問題は日本の至る場所で、現在も今後も顕在化してきます。つまり、高齢化率が60%を超える大崎下島は日本の近い未来の縮図と言えます。この問題を解決するために、訪問看護事業をベースとして展開し、地域住民の健康寿命を延ばすための疾病予防や健康増進、顔の見えるコミュニティづくりなど地域で高齢者を支えていく仕組みを構築します。また、IT技術などを活用し、島内で生活をする高齢者の日常に合わせて、看護師が島民を継続的にサポートしていける環境をつくることを目指します。そして、これらに加え、従来の医療介護職の働き方・生き方を新しく変えることで、私たちは日本の未来を切り開いて参ります。`,
  video: 'https://www.youtube.com/embed/_0WF3A5HVZ4?rel=0',

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
