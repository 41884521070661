import { Org2 } from '~/_legacy/models';

const org = new Org2({
  type: 'FurusatoOrganization',
  logo: '/ogawamotors/logo.jpg',
  cover: '/ogawamotors/cover.jpg',
  links: [],
  uid: 'ogawamotors',
  category: '有限会社',
  name: '小川モータース',
  area: '中国地方',
  sdgs: [11, 15, 17],
  social_impacts: [
    {
      name: '教育',
      value: 2,
      image: '/images/social-impacts/education.svg',
      image_disabled: '/images/social-impacts/education-disabled.svg',
    },
    {
      name: '就労支援',
      value: 2,
      image: '/images/social-impacts/job.svg',
      image_disabled: '/images/social-impacts/job-disabled.svg',
    },
    {
      name: '地域・まちづくり',
      value: 2,
      image: '/images/social-impacts/town.svg',
      image_disabled: '/images/social-impacts/town-disabled.svg',
    },
    {
      name: '環境',
      value: 1,
      image: '/images/social-impacts/environment.svg',
      image_disabled: '/images/social-impacts/environment-disabled.svg',
    },
    {
      name: '文化芸術',
      value: 0,
      image: '/images/social-impacts/culture.svg',
      image_disabled: '/images/social-impacts/culture-disabled.svg',
    },
    {
      name: '介護福祉',
      value: 0,
      image: '/images/social-impacts/nursing.svg',
      image_disabled: '/images/social-impacts/nursing-disabled.svg',
    },
    {
      name: '子育て支援',
      value: 0,
      image: '/images/social-impacts/parenting.svg',
      image_disabled: '/images/social-impacts/parenting-disabled.svg',
    },
    {
      name: '防災',
      value: 0,
      image: '/images/social-impacts/disaster.svg',
      image_disabled: '/images/social-impacts/disaster-disabled.svg',
    },
    {
      name: 'ホームレス支援',
      value: 0,
      image: '/images/social-impacts/help.svg',
      image_disabled: '/images/social-impacts/help-disabled.svg',
    },
    {
      name: 'スポーツ',
      value: 0,
      image: '/images/social-impacts/sports.svg',
      image_disabled: '/images/social-impacts/sports-disabled.svg',
    },
    {
      name: 'ヘルスケア',
      value: 0,
      image: '/images/social-impacts/health-care.svg',
      image_disabled: '/images/social-impacts/health-care-disabled.svg',
    },
    {
      name: 'ソーシャルキャピタル',
      value: 3,
      image: '/images/social-impacts/networking.svg',
      image_disabled: '/images/social-impacts/networking-disabled.svg',
    },
  ],
  community_members: [
    {
      id: 1,
      name: '組織内メンバー',
      value: 11,
    },
    {
      id: 1,
      name: '組織外サポーター',
      value: 105,
    },
    {
      id: 1,
      name: '組織外連携者',
      value: 14,
    },
  ],
  vision:
    '今までも、これからも”つながり”を提供し続ける。暮らしの多様性に富んだ活気あふれる甲奴を目指して',
  mission: `大正時代からの永きに渡り自社を育ててくれた甲奴への恩返し・貢献のため、ヒトと地域資源（農地や自然環境）、そして外部の技術を結び付け、そこにアイディアをプラスすることで起業や雇用につなげていく。そのために甲奴に関係する人々と「向き合う」のではなく「寄り添う」スタンスで、人々が主体性と協調性を持ちながら、のびのびと活動できるゆるやかな関係性を持った創生と循環のプラットフォームを甲奴に生み出す。`,
  tags: [
    {
      id: 1,
      name: '地方創生',
    },
    {
      id: 1,
      name: '高齢化社会',
    },
    {
      id: 1,
      name: '空き家',
    },
    {
      id: 1,
      name: '耕作放棄地',
    },
  ],
  tag_text:
    '人口減と高齢化による担い手不足により、地域資源である食味に優れた米の栽培が衰退。休耕田の拡大が地域の魅力を低下させ、若年層が進学や就職を機に甲奴を離れる社会減が継続しており、高齢化による自然減と相まって人口減を加速させ、それが新たな空き家の発生や地区の産業衰退につながる、という悪循環に陥っていること。',
  message_title: `暮らしの多様性とSmall Businessをつなぐふるさと`,
  message_text: `私たちは、大正時代から形を変えながらも人々の「足」を支えるインフラとして甲奴に根付いてきた既存事業（自動車販売・修理・整備・ガソリンスタンド事業）を通じて引き続き、地域の生活を支えることを大切にしております。 また更に甲奴を産業や暮らしのバラエティに富んだ活気あふれた場所にするため、大型雇用が見込める工場のような“大きな箱”ではなく“小さな箱” ＝Small Businessが多数生まれる環境を創出するきっかけとなるよう、地域内外の方々と共に日々取り組んでおります。`,
  activities: [
    {
      id: 1,
      title: 'スマイル10アール＋個装受託',
      text: '農家と都市部の企業を結ぶ「田んぼのオーナー制度」事業と追加料金でオリジナルパッケージへの個装の受託で，契約農家の所得向上だけでなく，地域内での雇用も創出。今後は，モノを米だけでなく，ブルーベリーやワインにも拡大して，耕作放棄地の解消や雇用創出などに取り組む。',
      image: '/ogawamotors/activity1.JPG',
    },
    {
      id: 1,
      title: 'Farmer’s Market',
      text: '広島県の新鮮な野菜・乳製品・焼き菓子・規格外野菜を販売する「Farmer’s Market」で，販売場所を企業版ふるさと納税制度で連携する企業のオフィスへ拡大する。',
      image: '/ogawamotors/activity2.jpg',
    },
    {
      id: 1,
      title: '現地研修',
      text: '農作業交流イベントなどの「現地研修」の参加者や内容を，企業版ふるさと納税制度で連携する企業の従業員や，甲奴の暮らし体験及びSocial Business実践にも拡大する。',
      image: '/ogawamotors/activity3.JPG',
    },
  ],
  owner_title: '代表取締役',
  owner: '小川 治孝',
  owner_image: '/ogawamotors/owner.jpg',
  since: '1988/9/1',
  postal_code: '7294102',
  prefecture: '広島県',
  address: '三次市甲奴町西野544-1',
  budget: '3億円＜5億円',
  full_time_staff: '~10',
  part_time_staff: '~10',
  volunteer: '100~500',
  client: `広島銀行`,
  performance: `令和元年度「ひろしま里山グッドアワード　さとやま未来大賞」
（小川商店として「スマイル10アール」事業で最高位受賞）
平成27年度「がんばる中小企業・小規模事業者300社」（小川商店として受賞）
平成27年版「小規模企業白書」掲載
平成31年度「中小・ベンチャー企業チャレンジ応援事業助成金」`,
  locations: '/ogawamotors/location.png',
  images: ['/ogawamotors/image1.jpg', '/ogawamotors/image2.jpg'],
  documents: [],

  // Sample below
  recruitments: [],
  follower_count: 1234,
  followers: [
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
    {
      id: 1,
      name: 'sample',
    },
  ],
  video: null,
  video_thumbnail: null,
  reviews: [
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
    {
      id: 1,
      created_at: '2021-08-22T13:15:49.210Z',
      text: 'sample',
      postable_user: {
        area: '東京都',
        birth: '非公開',
        gender: 'other',
        id: 1,
        name: 'samplesample',
      },
    },
  ],
  badges: [
    {
      id: 1,
      image: '/ogawamotors/badge.svg',
      title: 'ひろしま版里山エコシステム　支援対象団体',
      description:
        '地域課題の解決に取り組む中山間地域（里山・里海）の地域団体などの取り組みの質を「見える化」することで、その活動に対して共感する地域貢献に関心の高い企業とのマッチングを図り、企業版ふるさと納税を通じた財政的支援を受けるための仕組みです。',
      prefecture: '広島県',
      area: '中山間地域',
    },
  ],
  demographics: [
    {
      name: '地域',
      values: [
        {
          name: '関東',
          value: 5,
        },
        {
          name: '関西',
          value: 2,
        },
      ],
    },
    {
      name: '年齢',
      values: [
        {
          name: '20代',
          value: 4,
        },
        {
          name: '30代',
          value: 3,
        },
      ],
    },
    {
      name: '性別',
      values: [
        {
          name: '男',
          value: 5,
        },
        {
          name: '女',
          value: 2,
        },
      ],
    },
  ],
});

export default org;
