import { request, RequestOption } from '~/api/_utils/request';

import { authToken } from '.';

type PickedOption = Pick<RequestOption, 'isFullPath'>;

class LegacyRequest {
  constructor(private options: PickedOption) {}

  private getOptions(): PickedOption {
    // NOTE: legacyRequestが呼ばれる箇所ではpathを`apiUrl`関数で定義しているため、`isFullPath: true`の指定が必要
    return { ...this.options, isFullPath: true };
  }

  // withoutAuth(): LegacyRequest {
  //   return new LegacyRequest({ isWithAuth: false });
  // }

  // eitherAuth(): LegacyRequest {
  //   const hasToken = Boolean(authToken.get());
  //   return new LegacyRequest({ isWithAuth: hasToken });
  // }

  async GET<T = any>(path: string, query = {}): Promise<T> {
    return await request.GET<T>(path, query, this.getOptions());
  }

  async POST<T = any>(path: string, params = {}): Promise<T> {
    return await request.POST<T>(path, params, this.getOptions());
  }

  async PUT<T = any>(path: string, params = {}): Promise<T> {
    return await request.PUT<T>(path, params, this.getOptions());
  }

  async PATCH<T = any>(path: string, params = {}): Promise<T> {
    return await request.PATCH<T>(path, params, this.getOptions());
  }

  async DELETE<T = any>(path: string, params = {}): Promise<T> {
    return await request.DELETE<T>(path, params, this.getOptions());
  }
}

/** @deprecated 新しくapiを定義する場合は~/api/_utils/requestの`request`を使用する */
// const legacyRequest = new LegacyRequest({ isWithAuth: true });
const legacyRequest = new LegacyRequest({});
export default legacyRequest;
