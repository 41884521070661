import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'nurseandcraft',
  id: 1,
  total_amount: 2000000,
  title: 'とびしま海道で創る、医療介護職の新しい働き方',
  issues: ['集落の小規模化・高齢化', '老々介護', '地域の医療格差', '希薄する地域コミュニティ'],
  fund_uses: [
    {
      id: 1,
      title: 'IoTやxR技術を用いたサービスの実証実験及び商品開発費用　1,000,000円',
      description:
        '様々な資源が足りない島しょ部において、テクノロジーの導入は欠かせません。私たちは過疎地や離島の実証フィールドを有し、誰もが住み慣れた場所で医療や介護に対する不安がなくウェルビーイングな暮らしを実現できるよう、製品・技術・ソリューションの実用化に向けて検証を行っています。資金は、研究や実験で使用する備品等に使います。',
      image: '/nurse-and-craft/use1.jpg',
    },
    {
      id: 1,
      title: 'ファブリケーションラボの機器及び環境整備費用　1,000,000円',
      description:
        'デジタルクラフト工房では、デジタルファブリケーションによる福祉用具・家具の製造･開発を行います。ここに個人による自由なものづくりの場「ファブラボ」としての機能を持たせるために、3Dスキャナーやレーザーカッター等の環境整備のために資金を使います。',
      image: '/nurse-and-craft/use2.jpg',
    },
  ],
  purpose_title: '日本の介護問題を、国の社会保障制度に依存せず自分たちの手で切り開いていく',
  purpose_text: `施設から在宅へと日本の介護政策が進む中、①「機能不全に陥る社会保障制度」（ 叶わない自宅死、医療費の増大、医療介護人材不足など ） と②「潜在看護師（介護士）の増加」（医療事故への不安・責任の重さ、変則的で不規則な勤務体制、出産・育児によるブランクなど ）　という二つの問題は深まるばかりで、解決への道筋は依然として見通せない状況です。このままでは、日本中に介護難民や介護離職はあふれ、誰もが老後に不安を抱えながら生きることになります。それは健全で持続可能な社会とは言えず、私たち一人ひとりには未来に対して責任があります。私たちの目的は、誰もが人生の最期に「自分の人生は本当におもしろかった」と言える社会をつくることです。`,
  purpose_image: '/nurse-and-craft/purpose.jpg',
  project_title: '地域の中で高齢者を支え合う「まちぐるみの介護」ができる環境を目指して',
  project_text: `①訪問看護事業
  誰もが住み慣れた場所で最期の最期まで自立した人生を送れる仕組みのことで、訪問看護事業による医療の提供と健康維持・増進のためのサービス、まちづくりを行っていく。

  ② 地域で見守りができるサービスの確立
  IoTやAR技術を用いた疾病予防や健康維持・増進、見守りサービスを独自でつくる。また、要介護者の方には、個々の状況にあわせて、デジタルファブリケーションによる福祉用具・家具の開発を行う。

  ③ 看護師の自由な働き方の実現
  島嶼部では都市部と異なり、看護師や介護士、セラピストなどがまちに点在し、人々の健康を見守る仕組みが必要である。そのため、①②を実現することで、介護の専門家が島嶼部で自由な働き方を実現できることを支援する。`,
  project_image: '/nurse-and-craft/project.jpg',
  about: `とびしま海道周辺島々の過疎地では、人口減少によって医療・介護保険制度だけに依存したビジネスは経営的に成立せず、その結果、医療や介護サービスにおいて地域格差が生じています。このような問題は日本の至る場所で、現在も今後も顕在化してきます。つまり、高齢化率が60%を超える大崎下島は日本の近い未来の縮図と言えます。この問題を解決するために、訪問看護事業をベースとして展開し、地域住民の健康寿命を延ばすための疾病予防や健康増進、顔の見えるコミュニティづくりなど地域で高齢者を支えていく仕組みを構築します。また、IT技術などを活用し、島内で生活をする高齢者の日常に合わせて、看護師が島民を継続的にサポートしていける環境をつくることを目指します。そして、これらに加え、従来の医療介護職の働き方・生き方を新しく変えることで、私たちは日本の未来を切り開いて参ります。`,
  video: 'https://www.youtube.com/embed/_0WF3A5HVZ4?rel=0',

  // Sample below
  close_date: '2021/12/15',
  current_amount: 0,
});

export default presentation;
