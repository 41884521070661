import { CorpFurusatoPresentation } from '~/_legacy/models';

import OrgAPI from '../Org';

const presentation = new CorpFurusatoPresentation({
  org_uid: 'yohaku-bi',
  id: 8,
  total_amount: 2000000,
  title: '買い物難民を救う。産直市のローカルコンビニ化',
  issues: ['買い物難民', '集落の小規模化', '地域産業の衰退', '雇用の創出'],
  fund_uses: [
    {
      id: 1,
      title: '産直市の改修費用 (2,000,000円)',
      description: `産直市を改修する費用の一部として活用させていただきます（総予算500万円）。`,
      image: '/yohaku-bi-2022/use1.jpg',
    },
  ],
  purpose_title: '観光客だけでなく、地域住民に必要とされる産直市に',
  purpose_text: `　広島市佐伯区湯来町上水内地区。かつては旧町内でも一番人口も多く林業や観光業で栄えていましたが、今では一番高齢化が進み、住民は500人を切るまでになりました。2021年には農協のコンビニが撤退し、地区内で生鮮食品を買えるお店は消滅。最寄りのスーパーまで車で40分かかるようになり、車を持たないお年寄りには一大事となっています。
　
我々はその地区で「湯来特産品市場館」という約13坪の小さな産直市を運営しています。野菜や米、お土産品等を販売していますが、農協撤退後、肉や魚を買えるようにならないかという住民からの相談を数多く受けるようになりました。

本プロジェクトでは、産直市の”ローカルコンビニ化”を図ることで、観光客だけではなく、地域住民にとっても便利で楽しい場所を作りたいと考えています。また、ローカルコンビニがあることで、移住のハードルを下げることも目的の一つです。`,
  purpose_image: '/yohaku-bi-2022/purpose.jpg',
  project_title: '産直市をローカルコンビニに改修',
  project_text: `①産直市の改修（増床）
売り場面積約13坪の産直市を、約23坪に増床する改修工事を行います。具体的には軒下部分をすべて壁で囲い、販売スペースへ転換します。

②生鮮食品の販売許可のための機材の整備と仕入れの確立
肉や魚の販売許可を新たに取得するため、冷蔵ショーケース等を新たに整備します。また、地元住民も観光客も楽しめるよう、内陸部の肉・魚だけではなく、沿岸部のものも取り扱えるよう、仕入れルートを確立します。

③環境にやさしく、見た目もオシャレな日用品の販売
湯来町は広島の水源域でもあります。そうした自然環境を守ることと、地域住民だけでなく観光客も買いたくなるような、環境にやさしく、かつオシャレ日用品を販売します。

こうした取組を通じ、地域住民と観光客の接点を増やし、交流を促すことで、地住民にとっては新たな生きがいづくり、観光客にとっては、地元の話を聞けるような場となり、場の価値を高めていきます。また、売上を増やし、雇用の創出を狙います。`,
  project_image: '/yohaku-bi-2022/project.jpg',
  about: `湯来町の地域課題としては、柱の産業であった①林業②酪農業③観光業が、「収益性の低さ」と、厳しい状況を巻き返していくような「人材不足」、そして「アクセスの悪化」も要因となり、産業として成立しなくなった。仕事がないことで人口流出は止まらず、少子高齢化が加速している。また、空き家の掘り起こし・活用が進まず、一定数いる移住希望者に情報を提供できず、定住者を受け入れる機会を逃している。
　当社は、これらの課題を解決するため、下記のような事業を行い、産業を復興させ、子育て世代が暮らせ、かつ地域住民が幸せに暮らせる、持続可能な地域となることを目指し設立され、活動している。`,
  video: 'https://www.youtube.com/embed/pIqWdaDlHMA?rel=0',

  // Sample below
  close_date: '2022/12/15',
  current_amount: 0,
});

export default presentation;
