import Router from 'next/router';
import useSWRImmutable from 'swr/immutable';

import { UnauthorizedError } from '~/api/_utils/request';
import { CorporateT } from '~/state/corporate';
import { apiURL, request } from '~/utils';
import { getLoginUrlWithRedirectTo } from '~/utils/getLoginUrlWithRedirectTo';

type PickedCorporateT = Pick<
  CorporateT,
  | 'id'
  | 'uid'
  | 'logo'
  | 'cover'
  | 'materiality'
  | 'name'
  | 'title'
  | 'sdgs'
  | 'category'
  | 'category_postfix'
  | 'business'
  | 'social_vision'
  | 'interest'
  | 'email'
>;

type LegacyMethod = {
  image(): string;
};

export type CurrentCorporateT = PickedCorporateT &
  LegacyMethod & {
    type: 'corporate';
  };

type GetOption = {
  /** @default true */
  isRedirectWhenWithoutToken?: boolean;
};

export const CurrentCorporate = {
  /** @deprecated `apiGetV1CurrentCorporate`を使用する */
  async get(options: GetOption = {}): Promise<CurrentCorporateT> {
    const { isRedirectWhenWithoutToken = true } = options;

    try {
      const response = await request.GET<Record<string, PickedCorporateT>>(
        apiURL('current_corporate'),
      );

      const it = {
        ...response.corporate,
        type: 'corporate' as const,
        image() {
          return this.logo;
        },
      };
      return it;
    } catch (e: any) {
      if (e instanceof UnauthorizedError && isRedirectWhenWithoutToken) {
        Router.replace(getLoginUrlWithRedirectTo());
      }
      throw new Error(e);
    }
  },
};

/** @deprecated `useSWRV1CurrentCorporate`を使用する */
export function useSWRCurrentCorporate(option?: GetOption) {
  const swr = useSWRImmutable<CurrentCorporateT>('current_corporate', () =>
    CurrentCorporate.get(option),
  );
  return { ...swr, corporateUser: swr?.data };
}
