export type CorpFurusatoRecruitmentDataT = {
  id: number;
  cover: string;
  title: string;
  link: string;
};

export const CorpFurusatoRecruitmentData = [
  {
    id: 1,
    cover: '/furusato-hiroshima/cover@2x.png',
    title: '【2021年度】企業版ふるさと納税 - 地域貢献企業の募集',
    link: 'https://www.pref.hiroshima.lg.jp/site/hiroshimabansatoyamaekoshisutemu/satoyama-ecoshisutemu-r0301.html',
  },
  {
    id: 2,
    cover: '/furusato-hiroshima/cover@2x.png',
    title: '【2022年度】企業版ふるさと納税 - 地域貢献企業の募集',
    link: 'https://www.pref.hiroshima.lg.jp/site/hiroshimabansatoyamaekoshisutemu/satoyama-ecoshisutemu-r0300.html',
  },
  {
    id: 3,
    cover: '/furusato-hiroshima/3-cover-mini.png',
    title: '【2023年度】企業版ふるさと納税 - 地域貢献企業の募集',
    link: 'https://www.pref.hiroshima.lg.jp/site/hiroshimabansatoyamaekoshisutemu/satoyama-ecoshisutemu-r0300.html',
  },
] as const satisfies readonly CorpFurusatoRecruitmentDataT[];
