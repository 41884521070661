import { request, apiURL } from '~/utils';

export default class {
  static list() {
    return new Promise((resolve, reject) => {
      request

        .GET(apiURL('tags'))
        .then((obj) => {
          resolve(obj.tags);
        })
        .catch(reject);
    });
  }
}
